import React from 'react';
import '../css/ProductDetails.css'
import Colors from './Colors'
import DetailsThumb from './DetailsThumb';
import Loader from './loader.gif'
import axios from 'axios';
import { Link } from 'react-router-dom'
import { DataContext } from '../Context'
import defaultimage from './defaultimg.png'
import SameProductCauresel from './SameProductCauresel'
import NumberFormat from 'react-number-format'


class ProductDetails extends React.Component {
    static contextType = DataContext;
    state = {
        urlsImgs: [],
        products: [
            {
                "_id": "loading......",
                "title": "......",
                "src": [],
                "description": "loading.......................",
                "content": "loading ......",
                "price": 0.00,
                "colors": ["red", "black", "crimson", "teal"],
                "count": 1,
                "headers": []
            }
        ],
        loading: false,
        index: 0,
        quantity: 1,

    };

    myRef = React.createRef();

    handleTab = index => {
        this.setState({ index: index })
        const images = this.myRef.current.children;
        for (let i = 0; i < images.length; i++) {
            images[i].className = images[i].className.replace("active", "");
        }
        images[index].className = "active";
    };

    handleQuatinty = (event) => {
        this.setState({ quantity: event.target.value, })
    }
    refreshUl = (itemCode) => {
        const { db, ip, port, dbuser, dbpass, url } = this.context;

        window.scrollTo({ behavior: 'smooth', top: '0px' });
        this.setState({
            loading: true,
            quantity: 1,

        })

        axios
            .get(url + 'fetchItemDetails', {
                params: {
                    item_code: itemCode,
                    ip: ip,
                    db: db,
                    port: port,
                    dbuser: dbuser,
                    dbpass: dbpass
                }
            })
            .then((response) => {
                this.setState({
                    products: response.data.list,
                    loading: false,
                    urlsImgs: []

                })
                const { index } = this.state;
                this.fetchUrls();
                this.myRef.current.children[index].className = "active";

            })
            .catch((error) => {

                console.log(error);
            });


    }
    percentageoff = (val1, val2) => {
        var val3 = (val1 / val2) * 100;
        val3 = 100 - val3;
        return Number.parseFloat(-val3).toFixed(0);
    }
    componentDidMount() {
        const { db, ip, port, dbuser, dbpass, url } = this.context;
        const item_code = JSON.parse(localStorage.getItem('item_code'));

        window.scrollTo({ behavior: 'smooth', top: '0px' });
        this.setState({
            loading: true,

        })

        axios
            .get(url + 'fetchItemDetails', {
                params: {
                    item_code: item_code,
                    ip: ip,
                    db: db,
                    port: port,
                    dbuser: dbuser,
                    dbpass: dbpass
                }
            })
            .then((response) => {
                this.setState({
                    products: response.data.list,
                    loading: false,
                    urlsImgs: []

                })
                const { index } = this.state;
                this.fetchUrls();
                this.myRef.current.children[index].className = "active";

            })
            .catch((error) => {

                console.log(error);
            });



    }

    fetchUrls() {
        const { products } = this.state;
        const { url, ip, db, dbuser } = this.context;
        if (products.length) {
            products.forEach((prdt) => {
                const sour = prdt.src;
                if (!sour.length) {
                    this.setState({ urlsImgs: [defaultimage] });
                }
                sour.forEach((prdct) => {

                    if (prdct !== null && prdct !== undefined) {
                        axios
                            .get(url + "getImage", {
                                headers: {
                                    "Content-Type": "application/octet-stream",
                                    Accept: "application/octet-stream",
                                },
                                responseType: "blob",
                                params: {
                                    name: prdct,
                                    ip: ip,
                                    db: db,
                                    dbuser: dbuser
                                }
                            })
                            .then((res) => {
                                if (res !== null && res !== undefined) {
                                    if (res.status === 200) {
                                        const blob = res.data;
                                        const url = URL.createObjectURL(blob);
                                        this.setState({ urlsImgs: [...this.state.urlsImgs, url] });
                                    } else {

                                    }
                                } else {

                                }
                            })
                            .catch((error) => {
                                if (error.response) {
                                    console.log("error response: " + error.response.status);
                                } else if (error.request) {
                                    console.log("error request: " + error.request);
                                } else {
                                    console.log("error: " + error.message);
                                }

                            });

                    }
                });
            });
            const { urlsImgs } = this.state;




        }


    }
    render() {
        const { products, index, loading, quantity, urlsImgs } = this.state;
        const { addCart, settingLink } = this.context;

        return (
            <div className="productWhole">
                <img src={Loader} alt="Loader Image" className={`search-loading ${loading ? 'show' : 'hide'}`}></img>
                {
                    products.map(item => (
                        <div className="details" key={item.item_code}>
                            <div className="big-img">
                                {urlsImgs.length && <img src={urlsImgs[index]} alt="" />}
                                {/* {!urlsImgs.length && <img src={defaultimage} alt="" />} */}
                            </div>

                            <div className="box">
                                {item.promotion_price > 0 && <div className="promotionsdiv" style={{ backgroundColor: settingLink.NAVBAR_COLOR }}>
                                    <label style={{ color: settingLink.NAVBAR_TXT_COLOR }}>{this.percentageoff(item.promotion_price, item.itemPrice)}%</label></div>}
                                {settingLink.ITEMS_OUTOFSTOCK_LABEL=="Y" &&<div className="promotionsdivl" style={{ backgroundColor: 'red' }}>
                                   {item.closing<1 && <label style={{ color: 'white'}}>Out Of Stock</label>}</div>}

                                <DetailsThumb images={urlsImgs} tab={this.handleTab} myRef={this.myRef} />
                                <div className="row">
                                    <span>{item.item_name}</span>
                                    <div className="pricespans">
                                        {item.promotion_price < 1 && <span>{settingLink.CURRENCY}:{item.price}</span>}
                                        {item.promotion_price > 0 && <div className="addButton">
                                            <label className="product__pricep">
                                                <label>{settingLink.CURRENCY}</label>
                                                <strong><NumberFormat className="lblamountvalu"
                                                    value={item.promotion_price} displayType={'text'} thousandSeparator={true} prefix={''} /></strong>
                                            </label></div>}
                                        {item.promotion_price > 0 && <div className="addButton">
                                            <label className="product__pricep">
                                                <label className="promolbl">{settingLink.CURRENCY}: {item.price}</label>
                                            </label></div>}
                                    </div>
                                </div>
                                {/* <Colors colors={item.colors} /> */}

                                <label>{item.description}</label>

                                <div><label className="item_group" style={{ color: settingLink.NAVBAR_TXT_COLOR, backgroundColor: settingLink.NAVBAR_COLOR }}>Group</label ><label className="groupcodelabel">{item.item_group}</label></div>
                                {item.headers.map(eacHeader => (
                                    <div>
                                        <label className="item_group"><label className="item_grouplabel">.</label> {eacHeader}</label>
                                    </div>
                                ))}
                                <p className="checkoutProduct__price">
                                    <small>Qty </small>
                                    <strong><input onChange={(e) => this.handleQuatinty(e)} className="checkout__input" type='number' defaultValue="1"></input></strong>
                                </p>
                                <div>
                                    {item.promotion_price < 1 && <strong>Total :
                                        <NumberFormat className="lblamountvalu"
                                            value={(quantity * item.itemPrice)} displayType={'text'} thousandSeparator={true} prefix={settingLink.CURRENCY} />


                                    </strong>}
                                    {item.promotion_price > 0 &&
                                        <strong className="product__pricep"> Total :
                                            <NumberFormat className="lblamountvalu"
                                                value={(quantity * item.promotion_price)} displayType={'text'} thousandSeparator={true} prefix={settingLink.CURRENCY} />
                                        </strong>}
                                </div>
                                <Link to="/">
                                    <button style={{ color: settingLink.CART_TEXT_COLOR, backgroundColor: settingLink.CART_BUTTON_COLOR }} className="cart" onClick={() => addCart(item, quantity)} >{settingLink.WEB_CART_TEXT}</button>
                                </Link>
                            </div>
                        </div>
                    ))
                }
                <SameProductCauresel refreshUl={this.refreshUl}></SameProductCauresel>
            </div>
        );
    };
}

export default ProductDetails;