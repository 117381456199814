import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Header from './components/Header'
import { DataProvider } from './components/Context'
import HeaderA from './components/HeaderA';
import Home from './components/Home';
import Products from './components/section/Products';
import Checkout from './components/section/Checkout';
import ProductDetails from './components/section/ProductDetails';
import Categories from './components/Categories';
import CheckoutC from './components/CheckoutC';
import LoginC from './components/LoginC';
import SignUp from './components/SignUp';
import OrderItems from './components/OrderItems';
import ConvertedOrder from './components/ConvertedOrder';
import ShowItems from './components/ShowItems';
import ShowDeliveredItems from './components/ShowDeliveredItems';
import Footer from './components/Footer';
import './index.css';
import TopSelling from './components/TopSelling'
import Promotions from './components/Promotions'
import AboutUs from './components/AboutUs'
import FooterAd from './components/FooterAd'
import CategoriesAd from './components/CategoriesAd'
import SearchResults from './components/SearchResults';
import ProductCategories from './components/ProductCategories';
import SideBarNav from './components/SideBarNav';
import Cart from './components/Cart';
import CompletePayments from './components/CompletePayments';
import HeaderB from './components/HeaderB';
import ImageSliderB from './components/ImageSliderB';
import HeaderMob from './components/HeaderMob';
import MobImageSlider from './components/MobImageSlider';
import ModProductList from './components/ModProductList';
import Ordering from './components/Ordering';
import Shipping from './components/Shipping';
import Returns from './components/Returns';
class App extends React.Component {
  render() {
    return (
      <DataProvider>
        <div className="app">
          <Router>
            <Switch>
              <Route path="/complete-payments">
                <HeaderB mainp={false} />
                <CompletePayments />
                <FooterAd />
              </Route>
              <Route path="/orderingInfo">
                <HeaderB mainp={false} />
                <Ordering />
                <FooterAd />
              </Route>

              <Route path="/shippingInfo">
                <HeaderB mainp={false} />
                <Shipping />
                <FooterAd />
              </Route> <Route path="/ReturnsInfo">
                <HeaderB mainp={false} />
                <Returns />
                <FooterAd />
              </Route>

              <Route path="/product-categories">
                <HeaderB mainp={false} />
                <ProductCategories />
                <FooterAd />
              </Route>
              <Route path="/shopping-cart">
                <HeaderB mainp={false} />
                <Cart />
                <FooterAd />
              </Route>
              <Route path="/navtest">
                <SideBarNav />
              </Route>
              <Route path="/search-results">
                <HeaderB mainp={false} />
                <SearchResults />
                <FooterAd />
              </Route>
              <Route path="/Aboutus">
                <HeaderB mainp={false} />
                <AboutUs />
                <FooterAd />
              </Route>
              <Route path="/topselligitems">
                <HeaderB mainp={false} />
                <TopSelling />
                <FooterAd />
              </Route> <Route path="/Promotions">
                <HeaderB mainp={false} />
                <Promotions />
                <FooterAd />
              </Route>
              <Route path="/LoginC">
                <LoginC />
                <FooterAd />
              </Route>
              <Route path="/Register">
                <SignUp />
                <FooterAd />
              </Route>
              <Route path="/orderItems">
                <HeaderB mainp={false} />
                <OrderItems />
                <FooterAd />
              </Route>
              <Route path="/delivered_orders">
                <HeaderB mainp={false} />
                <ConvertedOrder />
                <FooterAd />
              </Route>
              <Route path="/showItems">
                <HeaderB mainp={false} />
                <ShowItems />
                <FooterAd />
              </Route>
              <Route path="/show_delivered_items">
                <HeaderB mainp={false} />
                <ShowDeliveredItems />
                <FooterAd />
              </Route>
              <Route path="/checkout">
                <HeaderB mainp={false} />
                <CheckoutC />
                <FooterAd />
              </Route>
              <Route path="/product">
                <HeaderB mainp={false} />
                <ProductDetails />
                <FooterAd />
              </Route>
              <Route path="/Categories">
                <HeaderB mainp={false} />
                <Categories />
                <FooterAd />
              </Route>
              <Route path="/mobview">
                <HeaderMob />
                <MobImageSlider />
                <ModProductList />
                <FooterAd />
               
              </Route>
              <Route>
                <HeaderB mainp={true} />
                <ImageSliderB />
                <FooterAd />

              </Route>
              <Route path="/">
                <HeaderB mainp={true} />
                <ImageSliderB />
                <FooterAd />

              </Route>
            </Switch>
          </Router>
        </div>
      </DataProvider>
    );
  }
}

export default App;
