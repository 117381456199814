import React from 'react'
import * as FaIcons from 'react-icons/fa'
import *  as AiIcons from 'react-icons/ai'
import { DataContext } from './Context'
import { Link } from 'react-router-dom'
import './Navbar.css'
export class NavBar extends React.Component {
    static contextType = DataContext;
    state = {
        sidebar: false,
        filterText: ''
    }
    showNavBar = () => {
        this.setState({ sidebar: !this.state.sidebar })
        {this.props.methodName()}
    }
    handleKeyPress = (event) => {
        this.setState({
            filterText: event.target.value
        })

    }
    
    render() {
        const { sidebar } = this.state;
        const { groupsWithSubgroups } = this.context;
        const { filterText } = this.state;

        return (
            <div className="navbar">
                <Link to="#" classname="menu-bars"><FaIcons.FaBars className="menubar" onClick={this.showNavBar} /></Link>
                <div className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                    <div className='nav-menu-items' >
                        <div className="navbar-toggle">
                            <AiIcons.AiOutlineClose className="closeIcon" onClick={this.showNavBar} />
                        </div>
                        <input type="search" className="categoriesinput" onChange={this.handleKeyPress}></input>
                        {groupsWithSubgroups.filter(t => t.group_name.includes(filterText)).map((item, index) => {
                            return (
                                <li key={index} className='nav-text' onClick={(()=>{
                                    this.props.methodName();
                                    this.showNavBar();
                                    console.log('sjlsj');
                                })}
                                >
                                    <Link><span>{item.group_name}</span>
                                    </Link>  </li>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default NavBar
