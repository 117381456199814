import React, { Component } from 'react'
import { DataContext } from './Context'
import Loader from './loader.gif'
import CategoryItem from './CategoryItem'
import ItemsWithGroupsCaurosel from './section/ItemsWithGroupsCaurosel'
import axios from 'axios'
import Pitem from './Pitem'

export class Promotions extends Component {
    static contextType = DataContext;
    state = {
        loading: true,
        product: []
    }
    Capitalize = (name) => {
        return name.replace(/\b(\w)/g, s => s.toUpperCase());
    }
    componentDidMount() {
        const {  db, ip, port, dbuser, dbpass, url } = this.context;
        axios
            .get(url + 'getTodaysDeals', {
                params: {
                    ip: ip,
                    db: db,
                    port: port,
                    dbuser: dbuser,
                    dbpass: dbpass

                }
            })
            .then((response) => {
                this.setState({
                    product: response.data.list,
                    loading: false,

                })
            })
            .catch((error) => {

                console.log(error);
            });
    }

    render() {
        const { product, loading } = this.state;

        return (
            <div className="">
                <div className="">
                </div>
                <div className="">
                    <div className="">
                    </div>
                    <div ><label className="item_group_name">Top Deals Today</label></div>
                    <div className="main_content">
                        <img src={Loader} alt="Loader Image" className={`search-loading ${loading ? 'show' : 'hide'}`}></img>
                        {product.map(product => (
                             <Pitem
                             product={product}
                         />
                        ))
                        }

                    </div>

                    <ItemsWithGroupsCaurosel />


                </div>

            </div>
        );
    }
}

export default Promotions;
