import React, { useState, useEffect } from 'react';
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';
import '../css/Slider.css'
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

const ImageSlider = ({ slides, getClickedSliderDetails }) => {
  const [current, setCurrent] = useState(3);

  const length = slides.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      console.log("current : " + current);
      console.log("length : " + length);
      setCurrent(current === length - 1 ? 0 : current + 1);
    }, 20000);
    return () => clearInterval(interval);
  }, [current]);
  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  return (
    <div >

      <NavigateBeforeIcon style={{ fontSize: 80, fontWeight: 'italic' }} className='left-arrow' onClick={prevSlide} />
      <NavigateNextIcon style={{ fontSize: 80 }} className='right-arrow' onClick={nextSlide} />
      <section className="slider">
        {slides.map((slide, index) => {
          { console.log(slide.url) }
          return (
            <div
              className={index === current ? 'slide active' : 'slide'}
              key={index}
            >
              {index === current && (
                <img onClick={() => getClickedSliderDetails(slide.linkType, slide.linkCode)} src={slide.url} alt='travel image' className='image' />
              )}
            </div>
          );
        })}
      </section>
    </div >
  );
};

export default ImageSlider;
