import React, { Component } from 'react'
import './Subtotal.css'
import CurrencyFormat from "react-currency-format";
import { DataContext } from './Context'
import { Link, withRouter } from 'react-router-dom'

export class Subtotal extends Component {
    static contextType = DataContext;
    state = {
        location: ''
    }
    componentDidMount() {
        const { companyLocation } = this.context;
        if (companyLocation.length > 0) {

            this.setState({
                location: companyLocation[0].mainLocation

            })
            console.log(companyLocation[0].mainLocation);
        }

    }
    render() {
        const { user, completeOrder, cartTotal, cartItems, companyLocation } = this.context;
        const { location } = this.state;
        return (
            <div className="subtotal">
                <CurrencyFormat
                    renderText={(value) => (
                        <>
                            <p>
                                Sub total ({cartItems.length} items ):<strong>{value}</strong>
                            </p>
                            <small className="subtotal__gift">
                                <input type="checkbox" />
                                This may contain a gift
                            </small>
                        </>
                    )}

                    decimalScale={2}
                    value={cartTotal}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"KES"}

                />
                <div className="header__preliminaries1">
                    <label className="shipping__loc"> Shipping Location  </label>
                    <select onChange={(e) => {
                        const selectedGroup = e.target.value;
                        this.setState({ location: selectedGroup })
                        console.log("rrr");

                    }}
                        className="header__select1">
                        {companyLocation.map(eacResult => {
                            return (
                                <option value={eacResult.mainLocation} >
                                    {eacResult.mainLocation}
                                </option>

                            )
                        })}

                    </select>
                </div>
                <button onClick={() => completeOrder(user, location)} >Order Items</button>
            </div>
        );
    }
}

export default Subtotal;
