import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyBrtUNG8w1cKe_VYuuTxvt0lz-iWXLEo44",
  authDomain: "recipe-8700c.firebaseapp.com",
  databaseURL: "https://recipe-8700c.firebaseio.com",
  projectId: "recipe-8700c",
  storageBucket: "recipe-8700c.appspot.com",
  messagingSenderId: "880536544092",
  appId: "1:880536544092:web:8de0b5e5ead0281a59d799"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db  =firebaseApp.firestore();
const auth = firebase.auth();
export {db, auth};
