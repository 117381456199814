import React, { Component } from 'react'
import { DataContext } from './Context'
import { Link, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './signup.css'
import axios from 'axios';
import { auth } from './firebase'


export class SignUp extends Component {
    static contextType = DataContext;

    state = {
        fullNames: '',
        username: '',
        email: '',
        password: '',
        phoneNumber: '',
        confirm: '',
        redirectLogin: 'Register',
        isLogofied:false,
        logo:''
    }

    signUp = (event) => {
        const { fullNames, email, phoneNumber } = this.state;
        event.preventDefault();
        toast.configure();
        if (this.validate()) {
            { this.saveNewCustomer(fullNames, email, phoneNumber) };
        }


    };
    handleFullNameChange = (e) => {
        this.setState({ fullNames: e.target.value })
    };
    handleEmailChange = (e) => {
        this.setState({ email: e.target.value })
    };
    handlePhoneNumberChange = (e) => {
        this.setState({ phoneNumber: e.target.value })
    };

    handlePasswordChange = (e) => {
        this.setState({ password: e.target.value })
    };
    handlePasswordConfirm = (e) => {
        this.setState({ confirm: e.target.value })
    }
    getLogo = (name) => {
        const { url, ip, db, dbuser } = this.context;
        axios
            .get(url + "getImageLogo", {
                headers: {
                    "Content-Type": "application/octet-stream",
                    Accept: "application/octet-stream",
                },
                responseType: "blob",
                params: {
                    name: name,
                    ip: ip,
                    db: db,
                    dbuser: dbuser
                }
            })
            .then((response) => {

                this.setState({ logo: URL.createObjectURL(response.data),isLogofied:true })
            })
            .catch((error) => {

                console.log(error);

            });
    }
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        this.setState({
            windowAllow: (window.innerWidth <= 600)
        });
       
        const imageName = 'OCC.jpg'

    }
    getLogo = (name) => {
        const { url, ip, db, dbuser } = this.context;
        axios
            .get(url + "getImageLogo", {
                headers: {
                    "Content-Type": "application/octet-stream",
                    Accept: "application/octet-stream",
                },
                responseType: "blob",
                params: {
                    name: name,
                    ip: ip,
                    db: db,
                    dbuser: dbuser
                }
            })
            .then((response) => {

                this.setState({ logo: URL.createObjectURL(response.data),isLogofied:true })
            })
            .catch((error) => {

                console.log(error);

            });
    }
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        this.setState({
            windowAllow: (window.innerWidth <= 600)
        });
       
        const imageName = 'OCC.jpg'

    }
    validate() {
        let isValid = true;
        const { fullNames, confirm, email, password, phoneNumber } = this.state;

        if (confirm != password) {
            toast.error('Password Did Not Match!', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1500
            });
            isValid = false;
        }
        if (fullNames == '') {
            toast.error('FullNames Cannot Be Empty!', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1500
            });
            isValid = false;
        }
        if (email == '') {
            toast.error('Email Cannot Be Empty!', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1500
            });
            isValid = false;
        }
        if (password == '') {
            toast.error('PasswordCannot Be Empty!', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1500
            });
            isValid = false;
        }
        if (password.length < 8) {
            toast.error('Password Too Short!', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1500
            });
            isValid = false;
        }
        if (phoneNumber == '') {
            toast.error('Phone Number Cannot Be Empty!', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1500
            });
            isValid = false;
        }
        var pattern1 = new RegExp(/^[0-9\b]+$/);
        if (!pattern1.test(phoneNumber)) {
            toast.error('Please enter only number.', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1500
            });
            isValid = false;
        } else if (phoneNumber.length != 10) {
            toast.error('Please enter valid phone number.', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1500
            });
            isValid = false;
        }

        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(email)) {
            toast.error('Please enter valid email address', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1500
            });
            isValid = false;
        }
        return isValid;
    }
    saveNewCustomer = (fullNames, email, phoneNumber) => {
        const { db, ip, port, dbuser, dbpass, url } = this.context;
        toast.configure();

        axios.get(url + 'insertWebCustomer', {
            params: {
                LEDGER_NAME: fullNames,
                LEDGER_NUMBER: email,
                PHONE_NUMBER: phoneNumber,
                ip: ip,
                db: db,
                port: port,
                dbuser: dbuser,
                dbpass: dbpass
            }
        })
            .then((response) => {
                console.log(response.data);
                if (response.data == 'success') {
                    toast.success('SignUp Successfully!', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 1500
                    });
                    const { fullNames, confirm, email, password, phoneNumber } = this.state;
                    auth.createUserWithEmailAndPassword(email, password)
                        .then((auth) => {
                            console.log(auth);
                            if (auth) {
                                this.setState({
                                    redirectLogin: 'LoginC'
                                })
                                this.props.history.push('/LoginC');
                            }
                        }).catch((err) => {
                            alert(err.message)
                        })
                } else {
                    toast.error('Mobile Number Already used');
                }

            })
            .catch((error) => {

                console.log(error);

            });

    }
    render() {
        const { fullNames, confirm, email, password, phoneNumber, redirectLogin,isLogofied } = this.state;
        const{settingLink} = this.context;
        if (settingLink.WEB_SITE_LOGO !== undefined && !isLogofied) {
            this.getLogo(settingLink.WEB_SITE_LOGO);
        }
        return (
            <div className="login3" >
                <Link to='/'>
                    <img className="login__logo" src={this.state.logo}></img></Link>
                <div className="login__container2">
                    <h1>Create Account</h1>
                    <form>
                        <label>Your Name</label>
                        <input className="field" value={this.fullNames} onChange={this.handleFullNameChange}
                            type="text"></input>
                        <label>Phone Number</label>
                        <input className="field" value={this.phoneNumber} onChange={this.handlePhoneNumberChange}
                            type="text"></input>
                        <label>Email</label>
                        <input className="field" value={this.email} onChange={this.handleEmailChange}
                            type="text"></input>
                        <label>Password</label>
                        <input className="field" value={this.password} onChange={this.handlePasswordChange}
                            type="password"></input>
                        <div><label className="smallabel">Password Must Contain Atleast 8 Characters</label></div>
                        <label>Re-enter Password</label>
                        <input className="field" value={this.confirm} onChange={this.handlePasswordConfirm}
                            type="password"></input>
                        <Link to={'/' + redirectLogin}>
                            <button type="submit" onClick={this.signUp} className="login__signUpButton">Sign Up</button></Link>
                        <div><label className="smallabel">Already have an account?</label><Link to="/LoginC"><label className="signinlink">Sign-In</label></Link></div>

                    </form>
                </div>

            </div>


        )
    }
}
export default withRouter(SignUp)
