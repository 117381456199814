import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import axios from 'axios'
import ReactStars from "react-rating-stars-component";
import defaultimage from './defaultimg.png'
import { DataContext } from './Context'
import './Completepaymentcomp.css'
export default class Completepaymentcomp extends Component {
    static contextType = DataContext;
    state = {
        image: defaultimage,
    };
    fetchImageBytes(imageName) {
        const { url, ip, db, dbuser } = this.context;
        if (imageName !== null && imageName !== undefined) {
            axios
                .get(url + "getImage", {
                    headers: {
                        "Content-Type": "application/octet-stream",
                        Accept: "application/octet-stream",
                    },
                    responseType: "blob",
                    params: {
                        name: imageName,
                        ip: ip,
                        db: db,
                        dbuser: dbuser
                    }
                })
                .then((res) => {
                    if (res !== null && res !== undefined) {
                        if (res.status === 200) {
                            const blob = res.data;
                            const url = URL.createObjectURL(blob);
                            console.log("url: " + url);
                            this.setState({
                                image: url,
                            });
                            return url;
                        } else {
                            console.log(res.statusText);
                            this.setState({
                                image: defaultimage,
                            });
                            return defaultimage;
                        }
                    } else {
                        this.setState({
                            image: defaultimage,
                        });
                        return defaultimage;
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error response: " + error.response.status);
                    } else if (error.request) {
                        console.log("error request: " + error.request);
                    } else {
                        console.log("error: " + error.message);
                    }
                    this.setState({
                        image: defaultimage,
                    });
                    return defaultimage;
                });
        }
    }
    componentDidMount() {
        this.fetchImageBytes(this.props.product.src[0]);
    }
    render() {
        const { setItemCode ,settingLink} = this.context;
        return (
            <div className="Completepaymentcomp">
                <img className="yourorderimg" src={this.state.image}></img>
                <div className="itemsdivcart3">
                    <label className="cartsimilarname">{this.props.product.shortItemName}</label>
                    <div className="cartsimilarstars">
                        <ReactStars
                            edit={false}
                            count={5}
                            // onChange={ratingChanged}
                            size={14}
                            color={"#ffd700"}
                            activeColor="#ffd700"
                        />
                    </div>
                    <label className="cartsimilarprice">{settingLink.CURRENCY}: {this.props.product.price}</label>
                    <label className="cartsimalerbutton">
                        Qty: {this.props.product.quantity} </label>
                </div>


            </div>
        )
    }
}
