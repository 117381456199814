import React from 'react'
import { Link } from 'react-router-dom'
import defaultimage from './defaultimg.png'
import axios from 'axios'
import { DataContext } from './Context'
import NumberFormat from 'react-number-format'
export class CategoryItem extends React.Component {
    static contextType = DataContext;
    state = {
        image: defaultimage,
    };
    fetchImageBytes(imageName) {
        const { url, ip, db, dbuser } = this.context;
        if (imageName !== null && imageName !== undefined) {
            axios
                .get(url + "getImage", {
                    headers: {
                        "Content-Type": "application/octet-stream",
                        Accept: "application/octet-stream",
                    },
                    responseType: "blob",
                    params: {
                        name: imageName,
                        ip: ip,
                        db: db,
                        dbuser: dbuser
                    }
                })
                .then((res) => {
                    if (res !== null && res !== undefined) {
                        if (res.status === 200) {
                            const blob = res.data;
                            const url = URL.createObjectURL(blob);
                            console.log("url: " + url);
                            this.setState({
                                image: url,
                            });
                            return url;
                        } else {
                            console.log(res.statusText);
                            this.setState({
                                image: defaultimage,
                            });
                            return defaultimage;
                        }
                    } else {
                        this.setState({
                            image: defaultimage,
                        });
                        return defaultimage;
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error response: " + error.response.status);
                    } else if (error.request) {
                        console.log("error request: " + error.request);
                    } else {
                        console.log("error: " + error.message);
                    }
                    this.setState({
                        image: defaultimage,
                    });
                    return defaultimage;
                });
        }
    }
    percentageoff = (val1, val2) => {
        var val3 = (val1 / val2) * 100;
        val3 = 100 - val3;
        return Number.parseFloat(-val3).toFixed(0);
    }
    componentDidMount() {
        this.fetchImageBytes(this.props.product.src[0]);
    }
    render() {
        const { setItemCode, settingLink } = this.context;
        return (
            <div className="card">
                {this.props.product.promotion_price > 0 && <div className="promotionsdiv" style={{ backgroundColor: settingLink.NAVBAR_COLOR }}>
                    <label style={{ color: settingLink.NAVBAR_TXT_COLOR }}>{this.percentageoff(this.props.product.promotion_price, this.props.product.itemPrice)}%</label></div>}

                <Link to={'/product'} >  <div className="card_img" onClick={() => setItemCode(this.props.product.item_code)}>
                    <img src={this.state.image}></img>
                </div></Link>
                <div className="card_header">
                <h2>{this.props.product.shortItemName}</h2>
                    {this.props.product.promotion_price > 0 &&
                        <label className="pricer">KES : <NumberFormat className="lb"
                            value={this.props.product.promotion_price} displayType={'text'} thousandSeparator={true} prefix={''} /></label>}
                    {this.props.product.promotion_price < 1 &&
                        <label className="pricer">KES : {this.props.product.price}</label>}
                    {this.props.product.promotion_price > 0 &&
                        <label className="promolbl">KES : {this.props.product.price}</label>}
                </div>
            </div>
        )
    }
}
export default CategoryItem

