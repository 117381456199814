import React, { Component } from "react";
import axios from "axios";
import AliceCarousel from "react-alice-carousel";
import { DataContext } from "./Context";
import SliderImageB from "./SliderImageB";
import Categorieson from "./Categorieon";
import "./imagesliderb.css";
import ItemsWithGroupsCaurosel from "./section/ItemsWithGroupsCaurosel";
import ReactPaginate from "react-paginate";
import ProductComponent from "./section/ProductComponent";
import Pitem from "./Pitem";


class ImageSliderB extends Component {
  static contextType = DataContext;
  state = {
    curentPage: 0,
    perPage: 10,
    windowAllow: false,
    sliderLoaded: false,
    SliderData: [],
    settingLink: {},
    height:-250
  };
  getClickedSliderDetails = (type, itemReq) => {
    const { filterWithGroups, setItemCode } = this.context;
    if (type == "CATEGORY") {
      filterWithGroups(itemReq, itemReq);
      this.props.history.push("/product-categories");
    } else if (type == "ITEM") {
      setItemCode(itemReq);
      this.props.history.push("/product");
    } else {
      console.log("no link available..");
    }
  };

  updateDimensions = () => {
    // this.setState({ width: window.innerWidth, height: window.innerHeight });
    this.setState({
      windowAllow: window.innerWidth <= 600,
    });
  };
  componentDidMount() {
  
    {
      this.fetchSliderNames();
    }
  }
  
  fetchSliderNames = () => {
    const { db, ip, port, dbuser, dbpass, url } = this.context;
    axios
      .get(url + "getSlidersNames", {
        params: {
          ip: ip,
          db: db,
          port: port,
          dbuser: dbuser,
          dbpass: dbpass,
        },
      })
      .then((response) => {
        {
          this.createSliderSliders(response.data.list.WEB_SITE_SLIDERS);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  createSliderSliders = (slides) => {
    console.log("ddjdkjkdl" + slides);
    const { db, ip, dbuser, dbpass, url } = this.context;
    var sliderAddedData = [];
    this.setState({ sliderLoaded: true });
    slides.forEach((slide) => {
      var imageName = slide.imageName;
      console.log(imageName);
      if (imageName !== null && imageName !== undefined) {
        axios
          .get(url + "getSliders", {
            headers: {
              "Content-Type": "application/octet-stream",
              Accept: "application/octet-stream",
            },
            responseType: "blob",
            params: {
              name: imageName,
              ip: ip,
              db: db,
              dbuser: dbuser,
            },
          })
          .then((res) => {
            if (res !== null && res !== undefined) {
              if (res.status === 200) {
                const blob = res.data;
                const url = URL.createObjectURL(blob);
                const obj = {
                  linkCode: slide.linkCode,
                  imageName: slide.imageName,
                  linkDescription: slide.linkDescription,
                  linkType: slide.linkType,
                  url: url,
                };
                sliderAddedData.push(obj);
                this.setState({
                  SliderData: [...this.state.SliderData, obj],
                });
              } else {
              }
            } else {
            }
          })
          .catch((error) => {
            if (error.response) {
              console.log("error response: " + error.response.status);
            } else if (error.request) {
              console.log("error request: " + error.request);
            } else {
              console.log("error: " + error.message);
            }
          });
      }
    });
  };

  render() {
    const { SliderData } = this.state;
    const { products, pageNumber, itemsPerPage, changePage } = this.context;
    const pageVisited = pageNumber * itemsPerPage;
    const slicedData = pageVisited + itemsPerPage;
    const pageCount = Math.ceil(products.length / itemsPerPage);
    if(this.state.windowAllow){
        this.setState({height:-100})
    }
    return (
      <div className="">
        <div className="sliderspart">
          {" "}
          <div className="mobileSliders">
            <AliceCarousel autoPlay autoPlayInterval="3000">
              {SliderData.map((eachSlide) => {
                return (
                  <div className="">
                    <img src={eachSlide.url} className="sliderimg" />
                  </div>
                );
              })}
            </AliceCarousel>
          </div>
          
          <div className="innerdiv">
            <Categorieson />
          </div>{" "}
        </div>{" "}
      
        <div className="productList">
          {" "}
          {products.slice(pageVisited, slicedData).map((product) => (
         
              <Pitem product={product} />
          ))}
        </div>
        <div>
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
            disableInitialCallback={false}
          />
        </div>
        {/* <SameProductCauresel /> */} <ItemsWithGroupsCaurosel />
       
      </div>
    );
  }
}

export default ImageSliderB;
