import React, { Component } from 'react';
import './shoppingcart.css'
import Loader from './loader.gif'
import { DataContext } from './Context'
import CartItemComp from './CartItemComp'
import CurrencyFormat from "react-currency-format";
import CartSimilarprd from './CartSimilarprd'
import axios from 'axios';
import { withRouter } from 'react-router'

export  class Cart extends Component {
  static contextType = DataContext;
  state = {
    similaritms: []
  }
  fetchSimilarCartItems = () => {
    const { db, ip, port, dbuser, dbpass, url, } = this.context;
    const user = JSON.parse(localStorage.getItem('user'));
    console.log('web USer' + user)
    axios
      .get(url + 'fetchSimilarCartItems', {
        params: {
          WEB_USER: user,
          ip: ip,
          db: db,
          port: port,
          dbuser: dbuser,
          dbpass: dbpass
        }
      })
      .then((response) => {
        console.log(response.data.list)
        this.setState({
          similaritms: response.data.list

        })
      })
      .catch((error) => {

        console.log(error);
      });
  };
  componentDidMount() {
    { this.fetchSimilarCartItems() }
  }
  render() {
    const { settingLink, cartItems, cartTotal, loading } = this.context;
    const { similaritms } = this.state;
    return (
      <div className="shoppingmainpage">
        <img src={Loader} alt="Loader Image" className={`search-loading ${loading ? 'show' : 'hide'}`}></img>
        <div className="shoppingdetails">
          <div className="hpart">
            <label className="shoppingheaderlabel">Shopping Cart</label>

          </div>
          <div className="hpartprice">
            <label className="lprc">Price</label>
          </div>

          <div className="itemsspecdetails">
            {cartItems.map(item => (
         
              <CartItemComp key={item.item_code}
                description={item.description}
                itemPrice={item.itemPrice}
                quantity={item.quantity}
                order_number={item.orderNo}
                amount_value={item.amount_value}
                price={item.price}
                referenceNumber={item.reference_number}
                item_code={item.item_code}
                src={item.src}
                color={item.color}
                brand={item.brand}
              />
            ))}
          </div>
          <div className="botmpart">
            <CurrencyFormat
              renderText={(value) => (
                <span className="subtotalspan">
                  Subtotal ({cartItems.length} items ):<strong>{value}</strong>
                </span>

              )}
              decimalScale={2}
              value={cartTotal}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"KES "}

            />
          </div>
        </div>
        <div className="cartdetails">
          <div className="shoppingcartpart">
            <CurrencyFormat
              renderText={(value) => (
                <span className="subtotalspan">
                  Subtotal ({cartItems.length} items ):<strong>{value}</strong>
                </span>

              )}
              decimalScale={2}
              value={cartTotal}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"KES "}

            />
            <button className="check_btn" onClick={()=>{ this.props.history.push('/complete-payments');}}
             style={{ color: settingLink.NAVBAR_TXT_COLOR, backgroundColor: settingLink.NAVBAR_COLOR }}> Proceed To Checkout</button>
          </div>
          <div className="otheritemspart">
            <label className="otherpartlblheader">Similar Items Related To Your Cart Items</label>
            {similaritms.map(cartsimil => (
              <CartSimilarprd product={cartsimil} />
            ))}

          </div>
        </div>

      </div>
    );
  }
}
export default withRouter(Cart)
