import React from 'react'
import { DataContext } from './Context'
import './orderItems.css'
import axios from 'axios';
import { Link, withRouter } from 'react-router-dom'
import Loader from './loader.gif'

export class ConvertedOrder extends React.Component {
    static contextType = DataContext;

    state = {
        postedItems: [],
        loading: true
    }

    componentDidMount() {
        const { user, db, ip, port, dbuser, dbpass, url } = this.context;

        axios
            .get(url + 'getConvertedOrders', {
                params: {
                    WEB_USER: user,
                    ip: ip,
                    db: db,
                    port: port,
                    dbuser: dbuser,
                    dbpass: dbpass
                }
            })
            .then((response) => {
                console.log(response.data.list);
                this.setState({
                    postedItems: response.data.list,
                    loading: false
                })

            })
            .catch((error) => {
                console.log(error);
            });

    }
    render() {
        const { postedItems, loading } = this.state;
        const { getOrderItems } = this.context;
        return (<div>
            <img src={Loader} alt="Loader Image" className={`search-loading ${loading ? 'show' : 'hide'}`}></img>
            <div className="pendingOrdersheader"><label>Delivered Orders</label></div>
            {postedItems.map(order => (
                <div className="pendingorders" key={order.order_number}>
                    <div className="pendingorderHeader"><label className="refrencenu"> {order.reference_number}</label>
                        <button className="showitembutton"
                            onClick={() => {
                                getOrderItems(order.order_date, order.order_type, order.order_number, order.order_count, order.total_amount);
                                this.props.history.push('/show_delivered_items')
                            }}>Show Items</button>
                    </div>
                    <label className="checkoutProduct__title1">
                        {"Order Number:" + order.order_number}
                    </label>
                    <label className="checkoutProduct__title1">
                        {"Items Ordered :" + order.order_count}
                    </label>
                    <label className="checkoutProduct__title1">
                        {"Total Amount KES:" + order.total_amount}
                    </label>
                    <label className="checkoutProduct__title1">
                        {"DELIVERED: YES"}
                    </label>

                </div>
            ))
            }
        </div>
        );
    }
}

export default withRouter(ConvertedOrder)
