import React, { Component } from 'react'
import './aboutuscomponent.css'
import defaultimage from './defaultimg.png'
import {
    DataContext
} from './Context';
import axios from 'axios'

export default class Aboutuscomponent extends Component {

    static contextType = DataContext;

    state = {

        imageUrl1: '',
        imageUrl2: ''
    }

    fetchImageBytes2() {
        const { url, ip, db, dbuser } = this.context;
        {
            axios
                .get(url + "getAboutUsImg", {
                    headers: {
                        "Content-Type": "application/octet-stream",
                        Accept: "application/octet-stream",
                    },
                    responseType: "blob",
                    params: {
                        name: this.props.details.VALUE3,
                        ip: ip,
                        db: db,
                        dbuser: dbuser
                    }
                })
                .then((res) => {
                    if (res !== null && res !== undefined) {
                        if (res.status === 200) {
                            const blob = res.data;
                            const url = URL.createObjectURL(blob);
                            this.setState({
                                imageUrl2: url,
                            });
                        } else {
                            this.setState({
                                imageUrl2: defaultimage,
                            });
                        }
                    } else {
                        this.setState({
                            imageUrl2: defaultimage,
                        });
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error response: " + error.response.status);
                    } else if (error.request) {
                        console.log("error request: " + error.request);
                    } else {
                        console.log("error: " + error.message);
                    }
                    this.setState({
                        imageUrl2: defaultimage,
                    });
                });
        }
    }
    fetchImageBytes1() {
        const { url, ip, db, dbuser } = this.context;

        {
            axios
                .get(url + "getAboutUsImg", {
                    headers: {
                        "Content-Type": "application/octet-stream",
                        Accept: "application/octet-stream",
                    },
                    responseType: "blob",
                    params: {
                        name: this.props.details.VALUE1,
                        ip: ip,
                        db: db,
                        dbuser: dbuser
                    }
                })
                .then((res) => {
                    if (res !== null && res !== undefined) {
                        if (res.status === 200) {
                            const blob = res.data;
                            const url = URL.createObjectURL(blob);
                            this.setState({
                                imageUrl1: url,
                            });
                        } else {
                            this.setState({
                                imageUrl1: defaultimage,
                            });
                        }
                    } else {
                        this.setState({
                            imageUrl1: defaultimage,
                        });
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error response: " + error.response.status);
                    } else if (error.request) {
                        console.log("error request: " + error.request);
                    } else {
                        console.log("error: " + error.message);
                    }
                    this.setState({
                        imageUrl1: defaultimage,
                    });
                });
        }
    }
    componentDidMount() {
        { this.fetchImageBytes1() }
        { this.fetchImageBytes2() }
    }
    render() {
        return (
            <div className="tt">
                <div className="aboutuscompmain">
                  
                    <div className="rightpart">
                        <img className="ourimg"
                            src={this.state.imageUrl1} />
                    </div></div>
                <div className="aboutuscompmain">
                    <div className="rightpart">
                        <img className="ourimg"
                            src={this.state.imageUrl2} />
                    </div>
                   
                </div>
            </div >
        )
    }
}
