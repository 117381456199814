import React from 'react'
import { DataContext } from './Context'
import './checkoutProduct.css'
import axios from 'axios'
import defaultimage from './defaultimg.png'

export class CheckoutProduct extends React.Component {
    static contextType = DataContext;
    state = {
        image: defaultimage,
    };
    fetchImageBytes(imageName) {
        const { url, ip, db, dbuser } = this.context;
        if (imageName !== null && imageName !== undefined) {
            axios
                .get(url + "getImage", {
                    headers: {
                        "Content-Type": "application/octet-stream",
                        Accept: "application/octet-stream",
                    },
                    responseType: "blob",
                    params: {
                        name: imageName,
                        ip: ip,
                        db: db,
                        dbuser: dbuser
                    }
                })
                .then((res) => {
                    if (res !== null && res !== undefined) {
                        if (res.status === 200) {
                            const blob = res.data;
                            const url = URL.createObjectURL(blob);
                            console.log("url: " + url);
                            this.setState({
                                image: url,
                            });
                            return url;
                        } else {
                            console.log(res.statusText);
                            this.setState({
                                image: defaultimage,
                            });
                            return defaultimage;
                        }
                    } else {
                        this.setState({
                            image: defaultimage,
                        });
                        return defaultimage;
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error response: " + error.response.status);
                    } else if (error.request) {
                        console.log("error request: " + error.request);
                    } else {
                        console.log("error: " + error.message);
                    }
                    this.setState({
                        image: defaultimage,
                    });
                    return defaultimage;
                });
        }
    }
    componentDidMount() {
        this.fetchImageBytes(this.props.src[0]);
    }

    render() {
        const { removeFromCart, changeQuantity } = this.context;
        return (
            <div className="checkoutProduct">
                <img className="checkoutproduct__image" src={this.state.image}></img>
                <div className="checkoutProduct__info">
                    <p className="checkoutProduct__title">
                        {this.props.description}
                    </p>
                    <p className="checkoutProduct__price">
                        <small>KES : </small>
                        <strong>{this.props.itemPrice}</strong>
                    </p>
                    <p className="checkoutProduct__price">
                        <small>Qty </small>
                        <strong><input onChange={(e) =>
                            changeQuantity(e, this.props.order_number, this.props.referenceNumber,
                                this.props.item_code, this.props.quantity, this.props.price)} className="checkout__input" type='number' defaultValue={this.props.quantity}></input></strong>
                    </p>
                    <p className="checkoutProduct__price">
                        <small>Total KES : </small>
                        <strong>{this.props.amount_value}</strong>
                    </p>

                    <button onClick={() => removeFromCart(this.props.order_number, this.props.referenceNumber, this.props.item_code)}>Remove From Cart</button>
                </div>

            </div>
        )
    }
}

export default CheckoutProduct
