import './HeaderB.css';
import React, { Component } from 'react'
import SearchIcon from '@material-ui/icons/Search';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import { DataContext } from './Context'
import { Button } from '@material-ui/core';
import Combobox from 'react-widgets/lib/Combobox'
import { Link, withRouter } from 'react-router-dom'
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { IconContext } from 'react-icons';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from 'axios'
import SideBarNav from './SideBarNav'
import AfterHeaderb from './AfterHeaderb';
import AfterHeader from './AfterHeader'

export class HeaderB extends Component {
    static contextType = DataContext;
    state = {
        query: '',
        sidebar: false,
        windowAllow: false,
        filterText: '',
        logo: '',
        isLogofied: false

    }
    handleOnInputChange = (event) => {
        const query = event.target.value;
        const { SeachItems } = this.context;
        this.setState({ query: event.target.value });
        if (event.key === 'Enter') {
            SeachItems(event.target.value);
        }

    };
    handleKeyPress = (event) => {
        const { query } = this.state;
        const { SeachItems } = this.context;
        if (event.key === 'Enter') {
            this.props.history.push('/search-results');
            SeachItems(event.target.value);
        }
    }
    signoutRequest = () => {
        const { user, setUser } = this.context;
        console.log(`user in Header -->${user}`);
        if (user.length > 0) {
            setUser('');
        }

    }
    Capitalize = (name) => {
        return name.replace(/\b(\w)/g, s => s.toUpperCase());
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }
    handleKeyPress1 = (event) => {
        this.setState({

            filterText: event.target.value.toString().toLowerCase()
        })

    }
    componentDidMount() {
        { this.getLogo() }
    }
    showNavBar = () => {

        this.setState({
            sidebar: !this.state.sidebar
        })

    }
    hexToRgbA = (hex) => {
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)';
        }
        throw new Error('Bad Hex-->' + hex);
    }
    getLogo = () => {
        const { db, ip, port, dbuser, dbpass, url } = this.context;
        axios
            .get(url + "getImageLogo", {
                headers: {
                    "Content-Type": "application/octet-stream",
                    Accept: "application/octet-stream",
                },
                responseType: "blob",
                params: {
                    ip: ip,
                    db: db,
                    dbuser: dbuser,
                    dbpass: dbpass,
                    port: port
                }
            })
            .then((response) => {

                this.setState({ logo: URL.createObjectURL(response.data), isLogofied: true })
            })
            .catch((error) => {

                console.log(error);

            });
    }

    render() {
        const showSidebar = () => this.setState({ sidebar: !this.state.sidebar });
        const { query, sidebar, windowAllow, filterText, isLogofied } = this.state;

        const { CompanyName, settingLink, filterWithSubGroups, setHomeData, user, cartItems, SeachItems, groupsWithSubgroups, filterWithGroups } = this.context;

        var companyname = '';
        var address = '';
        var city = '';
        var email = '';

        if (CompanyName[0] !== undefined) {
            companyname = CompanyName[0].FULL_NAME;
            address = CompanyName[0].ADDRESS;
            city = CompanyName[0].CITY;
            email = CompanyName[0].EMAIL_ADDRES;
        }
        var renderUser = 'Guest';
        var signin = 'Sign In';
        var signLink = '';
        let colors = ['orange', 'red', 'blue', 'purple']
        if (user.length > 0) {
            renderUser = user;
            signin = 'Sign Out'
            var signLink = '';
        } else {
            var signLink = 'LoginC';
        }

        if (settingLink.WEB_SITE_LOGO !== undefined && !this.state.isLogofied) {
            console.log(settingLink.NAVBAR_BELOW_TXT_NAVBAR);
            this.getLogo(settingLink.WEB_SITE_LOGO);
        }
        return (
            <div className="Headerb" style={{ backgroundColor: settingLink.NAVBAR_COLOR }}>
                <div className="toppart">
                    <label className="welhead" style={{ color: settingLink.NAVBAR_TXT_COLOR }}>
                        Welcome to  {this.Capitalize(companyname.toLowerCase())}</label>

                    <div><label className="welheadu" style={{ color: settingLink.NAVBAR_TXT_COLOR }}>
                    <a className="welheadu" style={{ color: settingLink.NAVBAR_TXT_COLOR }} href={"mailto:"+settingLink.EMAIL_LINK}>Email: {settingLink.EMAIL_LINK}</a>
                           / Phone: {settingLink.TELEPHONES_LINK}</label>
                       
                    </div>

                </div>
                <div className="navbar">
                    <div className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                        <SideBarNav methodclose={this.showNavBar}
                            user={renderUser} setting={settingLink} />


                    </div>
                </div>
                <div className="logosearch" style={{ backgroundColor: settingLink.NAVBAR_BELOW_NAVBAR }}>
                    <img className="logob" onClick={() => { this.props.history.push('/') }} src={this.state.logo} />
                    <FaIcons.FaBars size="20px" style={{ color: settingLink.NAVBAR_BELOW_TXT_NAVBAR, cursor: 'pointer', marginRight: "20px" }} onClick={() => { this.showNavBar() }} />
                    <div className="wrap">
                        <div className="search">
                            <input type="text" className="searchTerm"
                                onChange={this.handleOnInputChange}
                                onKeyPress={this.handleKeyPress}
                                placeholder="Search For Products" />
                            <button type="submit" className="searchButton">
                                <Link to="/search-results">
                                    <SearchIcon onClick={() => SeachItems(query)}></SearchIcon>
                                </Link>
                            </button>
                        </div>
                    </div>


                    <div className="header__preliminarie" style={{ paddingTop: "10px", paddingBottom: "10px" }}>

                        <div className="header_nav">
                            <Link to={"/" + signLink} style={{ textDecoration: "none" }}>
                                <div className="header__option">
                                    <span className="header__optionLineOne" style={{ color: settingLink.NAVBAR_BELOW_TXT_NAVBAR }}>
                                        {renderUser}
                                    </span>
                                    <span className="header__optionLineTwo" style={{ color: settingLink.NAVBAR_BELOW_TXT_NAVBAR }} onClick={() => this.signoutRequest()}>
                                        {signin}
                                    </span>
                                </div>
                            </Link>
                            <Link to="/orderItems" style={{ textDecoration: "none" }}>
                                <div className="header__option">
                                    <span className="header__optionLineOne" style={{ color: settingLink.NAVBAR_BELOW_TXT_NAVBAR }}>
                                        Pending
                                    </span>
                                    <span className="header__optionLineTwo" style={{ color: settingLink.NAVBAR_BELOW_TXT_NAVBAR }}>
                                        Orders
                                    </span>
                                </div>
                            </Link >
                            <Link to="/delivered_orders" style={{ textDecoration: "none" }}>
                                <div className="header__option">
                                    <span className="header__optionLineOne" style={{ color: settingLink.NAVBAR_BELOW_TXT_NAVBAR }}>
                                        completed
                                    </span>
                                    <span className="header__optionLineTwo" style={{ color: settingLink.NAVBAR_BELOW_TXT_NAVBAR }}>
                                        Orders
                                    </span>
                                </div></Link>
                            <Link to="/shopping-cart" style={{ textDecoration: "none" }}>
                                <div className="header_optionBasket" style={{ color: settingLink.NAVBAR_BELOW_TXT_NAVBAR }}>
                                    <ShoppingBasketIcon />
                                    <span className="header__optionLineTwo header_basketCount" style={{ color: settingLink.NAVBAR_BELOW_TXT_NAVBAR }}>{cartItems.length}</span>
                                </div>
                            </Link>
                        </div>
                    </div>

                </div>

                {this.props.mainp && <AfterHeader />}
                {!this.props.mainp && <AfterHeaderb />}
            </div>
        )
    }
}
export default withRouter(HeaderB)