import React, { Component } from 'react'
import './ProductCategories.css'
import { DataContext } from './Context'
import CategoryItem from './CategoryItem'
import Pitem from './Pitem'
import ReactPaginate from 'react-paginate';
import Collapsible from 'react-collapsible';
import { Collapse } from 'react-collapse';
import { UnmountClosed } from 'react-collapse';
import CustomizedAccordions from './CustomizedAccordions'
import { Dropdown } from 'react-bootstrap';
import 'react-dropdown/style.css';
import Loader from './loader.gif'
import FilterListIcon from '@material-ui/icons/FilterList';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default class ProductCategories extends Component {
    static contextType = DataContext;
    state = {
        options: [
            'one', 'two', 'three'
        ],
        brandsArrayData: [],
        colorArrayData: []

    }
    Capitalize = (name) => {
        return name.replace(/\b(\w)/g, s => s.toUpperCase());
    }
    hoverHandler = (event) => {
        const { settingLink } = this.context;
        event.target.style.background = settingLink.NAVBAR_COLOR;
        event.target.style.color = settingLink.NAVBAR_TXT_COLOR;
    }
    MouseOut = (event) => {
        event.target.style.background = "white";
        event.target.style.color = "#555555";
    }
    handleBrandsSelected = (event, group) => {
        const { searchWithBrandsandColors } = this.context;
        if (event.target.checked) {
            this.state.brandsArrayData.push((event.target.value));
            searchWithBrandsandColors(group, this.state.brandsArrayData, this.state.colorArrayData);
        }

        if (!event.target.checked) {
            console.log("reached-->" + event.target.value);
            const newList = this.state.brandsArrayData.filter((item) => item !== event.target.value);
            console.log(newList);
            this.setState({
                brandsArrayData: newList
            }, () => {
                searchWithBrandsandColors(group, this.state.brandsArrayData, this.state.colorArrayData);
            });

        }
    }
    handleColorSelected = (event, group) => {
        const { searchWithBrandsandColors } = this.context;
        if (event.target.checked) {
            this.state.colorArrayData.push((event.target.value));
            searchWithBrandsandColors(group, this.state.brandsArrayData, this.state.colorArrayData);
        }
        if (!event.target.checked) {
            const newList = this.state.colorArrayData.filter((item) => item !== event.target.value);
            console.log(newList);
            this.setState({
                colorArrayData: newList
            }, () => {
                searchWithBrandsandColors(group, this.state.brandsArrayData, this.state.colorArrayData);
            });

        }


    }
    render() {
        const { products, loading, itemGroupName, subCategoryName, filterWithSubGroups, filterWithGroups, settingLink, groupsWithSubgroups, itemGroupItems, sortPriceLowToHighItemGroups,
            sortPriceHighToLowItemGroups, pageNumberGroup, itemsPerPageGroup, changePageGroup } = this.context;
        const pageVisited = pageNumberGroup * itemsPerPageGroup;
        const slicedData = pageVisited + itemsPerPageGroup;
        const pageCount = Math.ceil(itemGroupItems.length / itemsPerPageGroup);

        return (
            <div >
                <div className="ProductC">
                    <div className="drowpdown">
                        <img src={Loader} alt="Loader Image" className={`search-loading ${loading ? 'show' : 'hide'}`}></img>
                        {groupsWithSubgroups.map((item, index) => {
                            return (
                                <div className="">

                                    <Dropdown className="dp"  >
                                        <Dropdown.Toggle className="dropdownButton" clavariant="success" id="dropdown-basic">
                                            <div className="dropdownButtondiv" onMouseOver={(e) => this.hoverHandler} >
                                                <label  > {this.Capitalize(item.group_name)}</label>  <ExpandMoreIcon></ExpandMoreIcon></div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdownbuttonItem">
                                            <div className="dropdownbars" onMouseOver={this.hoverHandler} onMouseOut={this.MouseOut}> <Dropdown.Item
                                                onClick={() => {
                                                    filterWithGroups(item.item_group)
                                                }}
                                                className="sub_item">{this.Capitalize('All')}</Dropdown.Item></div>
                                            {item.subgroups.map((group, index) => {
                                                return (
                                                    <div className="dropdownbars" onMouseOver={this.hoverHandler} onMouseOut={this.MouseOut}> <Dropdown.Item onClick={() => {
                                                        filterWithSubGroups(item.item_group, `${this.Capitalize(item.group_name)}->${this.Capitalize(group.sub_group_name)}`, group.sub_group)
                                                    }}
                                                        className="sub_item">{this.Capitalize(group.sub_group_name)}</Dropdown.Item></div>)
                                            })}
                                        </Dropdown.Menu>


                                    </Dropdown>
                                </div>

                            )
                        })}

                    </div>
                    <div className="mainHeader">

                        <div><label className="gdivh">({itemGroupName}) {itemGroupItems.itemsarray !== undefined && itemGroupItems.itemsarray.length}  result(s)</label></div>
                        <div className="sort-items">
                            <label>Sort By: </label>   <select onChange={(e) => {
                                const selectedGroup = e.target.value;
                                if (selectedGroup == "low") {
                                    { itemGroupItems.itemsarray !== undefined && sortPriceLowToHighItemGroups(itemGroupItems) }
                                }
                                if (selectedGroup == "high") {
                                    { itemGroupItems.itemsarray !== undefined && sortPriceHighToLowItemGroups(itemGroupItems) }
                                }

                            }}
                                className="header__select">
                                <option value="default">
                                    Featured
                                </option>
                                <option value="low">
                                    Price :Low to High
                                </option>
                                <option value="high">
                                    Price :High to Low
                                </option>

                            </select>
                        </div>
                    </div>

                    <div className="product_categories">

                        <div className="product-categoriesLeft">
                            <div className="category_color">
                                <div className="head_brand_category">
                                    <div className="hbc" style={{
                                        backgroundColor: settingLink.NAVBAR_COLOR,
                                        color: settingLink.NAVBAR_TXT_COLOR
                                    }}
                                    ><FilterListIcon /><label className="" >Brands</label></div>
                                    {itemGroupItems.brandsarray !== undefined && itemGroupItems.brandsarray.map(brand => (
                                        <div className="" >   <input type="checkbox" id={brand.brand}
                                            name={brand.brandName} value={brand.brand} onChange={(e) => { this.handleBrandsSelected(e, brand.group) }}></input>
                                            <label htmlFor={brand.brand} className="inputCheckbox">{brand.brandName}</label> </div>
                                    ))}
                                </div>


                            </div>
                            <div className="category_color">
                                <div className="head_brand_category">
                                    <div className="hbc" style={{
                                        backgroundColor: settingLink.NAVBAR_COLOR,
                                        color: settingLink.NAVBAR_TXT_COLOR
                                    }}
                                    ><FilterListIcon /><label className="" >Colors</label></div>

                                    {itemGroupItems.colorsarray !== undefined && itemGroupItems.colorsarray.map(color => (
                                        <div className="" >   <input id={color.color} type="checkbox" name={color.colorName} value={color.color} onChange={(e) => { this.handleColorSelected(e, color.group) }}></input>
                                            <label htmlFor={color.color} className="inputCheckbox">{color.colorName}</label> </div>
                                    ))}

                                </div>

                            </div>
                        </div>
                        <div className="product-categoriesRight">
                            <div className="main_content" >
                                {itemGroupItems.itemsarray !== undefined && itemGroupItems.itemsarray.slice(pageVisited, slicedData).map(product => (
                                    <Pitem key={product.item_code}
                                        product={product} />
                                ))
                                }
                                {itemGroupItems.itemsarray === undefined && products.slice(pageVisited, slicedData).map(product => (
                                    <Pitem key={product.item_code}
                                        product={product} />
                                ))
                                }

                            </div>
                            <div className="divPagenate">
                                <ReactPaginate  containerClassName={'rwidget'}
                                    previousLabel={"previous"}
                                    nextLabel={"next"}
                                    pageCount={pageCount}
                                    onPageChange={changePageGroup}
                                    containerClassName={"paginationBttns"}
                                    previousLinkClassName={"previousBttn"}
                                    nextLinkClassName={"nextBttn"}
                                    disabledClassName={"paginationDisabled"}
                                    activeClassName={"paginationActive"}
                                    disableInitialCallback={false}
                                />


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
