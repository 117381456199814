import React, { Component } from 'react'
import { DataContext } from '../Context'
import Loader from './loader.gif'
import '../css/Products.css'
import 'react-slideshow-image/dist/styles.css'
import ImageSlider from './ImageSlider';
import ImageSliderMob from './ImageSliderMob';
import ProductComponent from './ProductComponent'
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { Link, withRouter } from 'react-router-dom'
import ItemsWithGroupsCaurosel from './ItemsWithGroupsCaurosel'
import SameProductCauresel from './SameProductCauresel'
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

export class Products extends Component {

    static contextType = DataContext;
    state = {
        curentPage: 0,
        perPage: 10,
        windowAllow: false,
        sliderLoaded: false,
        SliderData: [],
        settingLink: {}

    }
    getClickedSliderDetails = (type, itemReq) => {
        const { filterWithGroups, setItemCode } = this.context;
        if (type == "CATEGORY") {
            filterWithGroups(itemReq, itemReq);
            this.props.history.push('/product-categories');
        } else if (type == "ITEM") {
            setItemCode(itemReq)
            this.props.history.push('/product');
        } else {
            console.log("no link available..");
        }


    }

    updateDimensions = () => {
        // this.setState({ width: window.innerWidth, height: window.innerHeight });
        this.setState({
            windowAllow: (window.innerWidth <= 600)
        });
    };
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        this.setState({
            windowAllow: (window.innerWidth <= 600)
        });
        { this.fetchSliderNames() }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }
    fetchSliderNames = () => {
        const { db, ip, port, dbuser, dbpass, url } = this.context;
        axios
            .get(url + 'getSlidersNames', {
                params: {
                    ip: ip,
                    db: db,
                    port: port,
                    dbuser: dbuser,
                    dbpass: dbpass
                }
            })
            .then((response) => {

                { this.createSliderSliders(response.data.list.WEB_SITE_SLIDERS) }
            }).catch((error) => {

                console.log(error);
            });
    }
    createSliderSliders = (slides) => {
        console.log("ddjdkjkdl" + slides)
        const { db, ip, dbuser, dbpass, url } = this.context;
        var sliderAddedData = [];
        this.setState({ sliderLoaded: true })
        slides.forEach((slide => {
            var imageName = slide.imageName;
            console.log(imageName);
            if (imageName !== null && imageName !== undefined) {
                axios
                    .get(url + "getSliders", {
                        headers: {
                            "Content-Type": "application/octet-stream",
                            Accept: "application/octet-stream",
                        },
                        responseType: "blob",
                        params: {
                            name: imageName,
                            ip: ip,
                            db: db,
                            dbuser: dbuser
                        }
                    })
                    .then((res) => {
                        if (res !== null && res !== undefined) {
                            if (res.status === 200) {
                                const blob = res.data;
                                const url = URL.createObjectURL(blob);
                                const obj = {
                                    'linkCode': slide.linkCode,
                                    'imageName': slide.imageName,
                                    'linkDescription': slide.linkDescription,
                                    'linkType': slide.linkType,
                                    'url': url
                                };
                                sliderAddedData.push(obj);
                                this.setState({
                                    SliderData: [...this.state.SliderData, obj],
                                })
                            } else {


                            }
                        } else {

                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            console.log("error response: " + error.response.status);
                        } else if (error.request) {
                            console.log("error request: " + error.request);
                        } else {
                            console.log("error: " + error.message);
                        }

                    });
            }
        }));
    }

    render() {
        const { settingLink, setItemCode, products, addCart, loading, pageNumber, itemsPerPage, changePage, fetchItemDetails } = this.context;
        const pageVisited = pageNumber * itemsPerPage;
        const { windowAllow, sliderLoaded, SliderData } = this.state;
        const slicedData = pageVisited + itemsPerPage;
        const pageCount = Math.ceil(products.length / itemsPerPage);
        // if (settingLink.WEB_SITE_SLIDERS !== undefined && !sliderLoaded) {
        //     this.createSliderSliders(settingLink.WEB_SITE_SLIDERS);
        // }

        return (
            <div >
                <div className="productList">

                    <img src={Loader} alt="Loader Image" className={`search-loading ${loading ? 'show' : 'hide'}`}></img>
                    <div className="Home">
                        {!windowAllow && sliderLoaded && SliderData.length && <ImageSlider className="slidere" slides={SliderData} getClickedSliderDetails={this.getClickedSliderDetails} />}
                        {windowAllow && <ImageSliderMob className="slidere" slides={SliderData} />}
                    </div>
                    {/* {
                        products.slice(pageVisited, slicedData).map(product => (
                            <div className="product_p" key={product.item_code}>
                                <ProductComponent
                                    product={product}
                                />
                            </div>
                        ))
                    } */}

                </div >
                <div >
                    <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"paginationBttns"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"paginationActive"}
                        disableInitialCallback={false}
                    />

                </div>
                {/* <SameProductCauresel /> */}
                <ItemsWithGroupsCaurosel />

            </div>
        )
    }
}

export default withRouter(Products)
