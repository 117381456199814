import './Header.css';
import React, { Component } from 'react'
import SearchIcon from '@material-ui/icons/Search';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import { DataContext } from './Context'
import { Button } from '@material-ui/core';
import Combobox from 'react-widgets/lib/Combobox'
import { Link, withRouter } from 'react-router-dom'
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { IconContext } from 'react-icons';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from 'axios'
import SideBarNav from './SideBarNav'


export class HeaderA extends Component {
    static contextType = DataContext;


    state = {
        query: '',
        sidebar: false,
        windowAllow: false,
        filterText: '',
        logo: '',
        isLogofied: false

    }

    handleOnInputChange = (event) => {
        const query = event.target.value;
        const { SeachItems } = this.context;
        this.setState({ query: event.target.value });
        if (event.key === 'Enter') {
            SeachItems(event.target.value);
        }

    };
    handleKeyPress = (event) => {
        const { query } = this.state;
        const { SeachItems } = this.context;
        if (event.key === 'Enter') {
            this.props.history.push('/search-results');
            SeachItems(event.target.value);
        }
    }
    signoutRequest = () => {
        const { user, setUser } = this.context;
        console.log(`user in Header -->${user}`);
        if (user.length > 0) {
            setUser('');
        }

    }
    detectWindowSize() {

    }

    updateDimensions = () => {
        // this.setState({ width: window.innerWidth, height: window.innerHeight });
        this.setState({
            windowAllow: (window.innerWidth <= 600)
        });
    };
    getLogo = () => {
        const { db, ip, port, dbuser, dbpass, url } = this.context;
        axios
            .get(url + "getImageLogo", {
                headers: {
                    "Content-Type": "application/octet-stream",
                    Accept: "application/octet-stream",
                },
                responseType: "blob",
                params: {
                    ip: ip,
                    db: db,
                    dbuser: dbuser,
                    dbpass: dbpass,
                    port: port
                }
            })
            .then((response) => {

                this.setState({ logo: URL.createObjectURL(response.data), isLogofied: true })
            })
            .catch((error) => {

                console.log(error);

            });
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        this.setState({
            windowAllow: (window.innerWidth <= 600)
        });

        { this.getLogo(); }

    }


    Capitalize = (name) => {
        return name.replace(/\b(\w)/g, s => s.toUpperCase());
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }
    handleKeyPress1 = (event) => {
        this.setState({

            filterText: event.target.value.toString().toLowerCase()
        })

    }
    showNavBar = () => {

        this.setState({
            sidebar: !this.state.sidebar
        })

    }
    hexToRgbA = (hex) => {
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)';
        }
        throw new Error('Bad Hex-->' + hex);
    }
    render() {
        const showSidebar = () => this.setState({ sidebar: !this.state.sidebar });
        const { query, sidebar, windowAllow, filterText, isLogofied } = this.state;
        const { settingLink, filterWithSubGroups, setHomeData, user, cartItems, SeachItems, groupsWithSubgroups, filterWithGroups } = this.context;
        var renderUser = 'Guest';
        var signin = 'Sign In';
        var signLink = '';
        let colors = ['orange', 'red', 'blue', 'purple']
        if (user.length > 0) {
            renderUser = user;
            signin = 'Sign Out'
            var signLink = '';
        } else {
            var signLink = 'LoginC';
        }


        return (
            <div className="on__header" style={{ backgroundColor: settingLink.NAVBAR_COLOR }}>
                {windowAllow && <div className="topHeader" style={{ backgroundColor: settingLink.NAVBAR_COLOR }}> <img className="header__logo"
                    src={this.state.logo}></img></div>}
                <div className='header' style={{ backgroundColor: settingLink.NAVBAR_COLOR }}>
                    {!windowAllow && <Link to="/">
                        <img className="header__logo"
                            src={this.state.logo}></img>

                    </Link>}
                    {!windowAllow && <div className="header__search" style={{ backgroundColor: settingLink.NAVBAR_COLOR }} >

                        <select onChange={(e) => {
                            const selectedGroup = e.target.value;
                            filterWithGroups(selectedGroup, selectedGroup);
                            this.props.history.push('/product-categories');

                        }}
                            className="header__select">
                            <option value="all">
                                All
                            </option>
                            {groupsWithSubgroups.map(eacResult => {
                                return (
                                    <option value={eacResult.item_group} >
                                        {eacResult.group_name}
                                    </option>

                                )
                            })}

                        </select>
                        <input className="header_searchInput"
                            onChange={this.handleOnInputChange}
                            onKeyPress={this.handleKeyPress}
                            type="text" />
                        <Link to="/search-results">
                            <SearchIcon onClick={() => SeachItems(query)} className="header__searchIcon"></SearchIcon>
                        </Link>
                    </div>}

                    {!windowAllow && <div className="header_nav" style={{ backgroundColor: settingLink.NAVBAR_COLOR }}>
                        <Link to={"/" + signLink} style={{ textDecoration: "none" }}>
                            <div className="header__option">
                                <span className="header__optionLineOne" style={{ color: settingLink.NAVBAR_TXT_COLOR }}>
                                    {renderUser}
                                </span>
                                <span className="header__optionLineTwo" style={{ color: settingLink.NAVBAR_TXT_COLOR }} onClick={() => this.signoutRequest()}>
                                    {signin}
                                </span>
                            </div>
                        </Link>
                        <Link to="/orderItems" style={{ textDecoration: "none" }}>
                            <div className="header__option">
                                <span className="header__optionLineOne" style={{ color: settingLink.NAVBAR_TXT_COLOR }}>
                                    Pending
                                </span>
                                <span className="header__optionLineTwo" style={{ color: settingLink.NAVBAR_TXT_COLOR }}>
                                    Orders
                                </span>
                            </div>
                        </Link >
                        <Link to="/delivered_orders" style={{ textDecoration: "none" }}>
                            <div className="header__option">
                                <span className="header__optionLineOne" style={{ color: settingLink.NAVBAR_TXT_COLOR }}>
                                    completed
                                </span>
                                <span className="header__optionLineTwo" style={{ color: settingLink.NAVBAR_TXT_COLOR }}>
                                    Orders
                                </span>
                            </div></Link>
                        <Link to="/shopping-cart" style={{ textDecoration: "none" }}>
                            <div className="header_optionBasket" style={{ color: settingLink.NAVBAR_TXT_COLOR }}>
                                <ShoppingBasketIcon />
                                <span className="header__optionLineTwo header_basketCount" style={{ color: settingLink.NAVBAR_TXT_COLOR }}>{cartItems.length}</span>
                            </div>
                        </Link>
                    </div>}

                </div>

                {windowAllow && <div className="header__preliminaries" style={{ paddingTop: "10px", paddingBottom: "10px", backgroundColor: settingLink.NAVBAR_COLOR }}>

                    <div className="header_nav">
                        <Link to={"/" + signLink} style={{ textDecoration: "none" }}>
                            <div className="header__option">
                                <span className="header__optionLineOne" style={{ color: settingLink.NAVBAR_TXT_COLOR }}>
                                    {renderUser}
                                </span>
                                <span className="header__optionLineTwo" style={{ color: settingLink.NAVBAR_TXT_COLOR }} onClick={() => this.signoutRequest()}>
                                    {signin}
                                </span>
                            </div>
                        </Link>
                        <Link to="/orderItems" style={{ textDecoration: "none" }}>
                            <div className="header__option">
                                <span className="header__optionLineOne" style={{ color: settingLink.NAVBAR_TXT_COLOR }}>
                                    Pending
                                </span>
                                <span className="header__optionLineTwo" style={{ color: settingLink.NAVBAR_TXT_COLOR }}>
                                    Orders
                                </span>
                            </div>
                        </Link >
                        <Link to="/delivered_orders" style={{ textDecoration: "none" }}>
                            <div className="header__option">
                                <span className="header__optionLineOne" style={{ color: settingLink.NAVBAR_TXT_COLOR }}>
                                    completed
                                </span>
                                <span className="header__optionLineTwo" style={{ color: settingLink.NAVBAR_TXT_COLOR }}>
                                    Orders
                                </span>
                            </div></Link>
                        <Link to="/checkout" style={{ textDecoration: "none" }}>
                            <div className="header_optionBasket" style={{ color: settingLink.NAVBAR_TXT_COLOR }}>
                                <ShoppingBasketIcon />
                                <span className="header__optionLineTwo header_basketCount" style={{ color: settingLink.NAVBAR_TXT_COLOR }}>{cartItems.length}</span>
                            </div>
                        </Link>
                    </div>
                </div>}
                {windowAllow && <div className="header__search" style={{ marginTop: "2px", marginBottom: "6px", marginLeft: "10px", marginRight: "10px" }}>

                    <input className="header_searchInput"
                        onChange={this.handleOnInputChange}
                        onKeyPress={this.handleKeyPress}
                        type="text" />
                    <Link to="/search-results">
                        <SearchIcon onClick={() => SeachItems({ query })} className="header__searchIcon"></SearchIcon>
                    </Link>
                </div>}
                <div className="header_btm" style={{ backgroundColor: settingLink.NAVBAR_COLOR }}>

                    <div className="navbar">
                        <div className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                            <SideBarNav methodclose={this.showNavBar}
                                user={renderUser} setting={settingLink} />


                        </div>
                    </div>

                    <Link to="#" className="labelHeader" style={{ color: settingLink.NAVBAR_TXT_COLOR }}><FaIcons.FaBars className="" onClick={this.showNavBar} /><label onClick={this.showNavBar}> Menu</label></Link>

                    <Link to="/" style={{ textDecoration: "none" }}> <label className="labelHeader" onClick={setHomeData} style={{ color: settingLink.NAVBAR_TXT_COLOR }} variant="outlined" > Home</label></Link>
                    <Link to="/product-categories" style={{ textDecoration: "none" }}> <label className="labelHeader" style={{ color: settingLink.NAVBAR_TXT_COLOR }} variant="outlined" > Product & Services</label></Link>
                    <Link to="/Promotions" style={{ textDecoration: "none" }}> <label className="labelHeader" onClick={setHomeData} style={{ color: settingLink.NAVBAR_TXT_COLOR }} variant="outlined" > Todays Deals</label></Link>
                    <Link to="/topselligitems" style={{ textDecoration: "none" }}> <label className="labelHeader" style={{ color: settingLink.NAVBAR_TXT_COLOR }} variant="outlined" > Top Selling Items </label></Link>

                </div>

            </div>

        )
    }
}
export default withRouter(HeaderA)
