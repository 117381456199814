import React, { Component } from 'react'
import { DataContext } from '../Context'
import Carousel from 'react-elastic-carousel'
import { Link } from 'react-router-dom'
import '../css/Carousel.css'
import Caureseitemcomponent from './caureseitemcomponent'


export class ItemsWithGroupsCaurosel extends Component {
    static contextType = DataContext;

    Capitalize = (name) => {
        return name.replace(/\b(\w)/g, s => s.toUpperCase());
    }
    render() {
        const { filterWithGroups, setItemCode, itemsWithGroups } = this.context;
        const breakPoints = [
            { width: 500, itemsToShow: 1 },
            { width: 768, itemsToShow: 4 },
            { width: 1200, itemsToShow: 5 },
            { width: 1500, itemsToShow: 6 }]
        return (
            <div className="">
                <div>
                    {itemsWithGroups.map(product => (
                        <div className="itemGroupMain" key={product.item_group}>
                            <div className={'group_header'}><h2 className="item_header"> {this.Capitalize(product.group_name)}</h2>
                                <Link to="/product-categories" ><a onClick={() => filterWithGroups(product.item_group, product.group_name)}>Show All</a></Link>
                            </div>
                            <Carousel breakPoints={breakPoints}>
                                {product.items.map(items => (
                                    <div className="product_p_c">
                                        <Caureseitemcomponent
                                            product={items}>
                                        </Caureseitemcomponent>
                                    </div>
                                ))}

                            </Carousel>
                        </div>
                    ))
                    }
                </div>
            </div>
        );


    }
}
export default ItemsWithGroupsCaurosel;
