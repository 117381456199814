import React, { Component } from 'react';
import './afterheader.css'
import * as FaIcons from 'react-icons/fa'
import {
    DataContext
} from './Context';
import { Link, withRouter } from 'react-router-dom'

class AfterHeader extends Component {
    static contextType = DataContext;
    state = {};
   

    render() {
        const { settingLink, setHomeData } = this.context;
        return (
            <div className="header_bts" style={{ backgroundColor: settingLink.NAVBAR_COLOR }}>
                <div className="departpart">
                    <FaIcons.FaBars size="20px" style={{ cursor: 'pointer' }} />
                    <label>All Departments     </label>
                </div>
                {/* <Link to="/" style={{ textDecoration: "none", color: settingLink.NAVBAR_TXT_COLOR }}> <label className="labelHeader" onClick={setHomeData} style={{ color: settingLink.NAVBAR_TXT_COLOR }} variant="outlined" > Home</label></Link> */}
                <Link to="/product-categories" style={{ textDecoration: "none" }}> <label className="labelHeader" style={{ color: settingLink.NAVBAR_TXT_COLOR }} variant="outlined" > Product & Services</label></Link>
                <Link to="/Promotions" style={{ textDecoration: "none", color: settingLink.NAVBAR_TXT_COLOR }}> <label className="labelHeader" onClick={setHomeData} style={{ color: settingLink.NAVBAR_TXT_COLOR }} variant="outlined" > Todays Deals</label></Link>
                <Link to="/topselligitems" style={{ textDecoration: "none", color: settingLink.NAVBAR_TXT_COLOR }}> <label className="labelHeader" style={{ color: settingLink.NAVBAR_TXT_COLOR }} variant="outlined" > Top Selling Items </label></Link>
            </div>
        );
    }
}

export default AfterHeader;