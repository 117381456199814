import React from 'react'
import { DataContext } from './Context'
import './CategoriesPopUp.css'

export class CategoriesPopUp extends React.Component {
    static contextType = DataContext;
    render() {
        return (
            <div>
                <div className="popup-box">
                    <div className="box">
                        <span className="close-icon" onClick={this.props.handleClose}>x</span>
                        <labe>Search Categories</labe>
                        {this.props.content}
                    </div>
                </div>
            </div>
        )
    }
}
export default CategoriesPopUp
