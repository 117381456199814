import React, { Component } from 'react'
import { DataContext } from '../Context'
import Carousel from 'react-elastic-carousel'
import '../css/Carousel.css'
import CaureselItmcpnt from './caureselItmcpnt'
import axios from 'axios'


export class SameProductCauresel extends React.Component {
    static contextType = DataContext;
    state = {
        product: [],
        breakPoints: [
            { width: 500, itemsToShow: 1 },
            { width: 768, itemsToShow: 4 },
            { width: 1200, itemsToShow: 5 },
            { width: 1500, itemsToShow: 6 },
            { width: 1700, itemsToShow: 5 }]
    }

    componentDidMount() {

    }
    render() {
        const { breakPoints } = this.state;
        const { product } = this.context;

        return (
            <div className="mainnn">
                <div>

                    <div className="itemGroupMain">
                        <div className={'group_header'}>
                            <h2 className="item_header">Similar Products</h2>
                        </div>
                        <Carousel breakPoints={breakPoints}>
                            {product.map(items => (
                                <div className="product_p_c" key={items.item_code}>
                                    <CaureselItmcpnt refreshUl={this.props.refreshUl}
                                        product={items}>
                                    </CaureselItmcpnt>
                                </div>
                            ))}

                        </Carousel>
                    </div>


                </div>
            </div>
        );


    }
}
export default SameProductCauresel;
