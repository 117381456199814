import React, { Component } from 'react'
import { DataContext } from './Context'
import './CheckoutC.css'
import Loader from './loader.gif'
import { Link } from 'react-router-dom'
import Subtotal from './Subtotal'
import CheckoutProductord from './CheckoutProductord'
import axios from 'axios';
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';

export class ShowDeliveredItems extends Component {

    static contextType = DataContext;
    state = {
        cartItems: [],
        loading: true

    }

    componentDidMount() {
        const { order_type, order_number, db, ip, port, dbuser, dbpass, url,settingLink } = this.context;

        axios
            .get(url + 'getDeliverdItems', {
                params: {
                    orderNumber: order_number,
                    ip: ip,
                    db: db,
                    port: port,
                    dbuser: dbuser,
                    dbpass: dbpass,
                    order_type: order_type

                }
            })
            .then((response) => {
                this.setState({
                    cartItems: response.data.list,
                    loading: false

                })
            })
            .catch((error) => {

                console.log(error);
            });

    }

    render() {
        const { cartItems ,loading} = this.state;
        const { order_date, order_number, order_count, order_amount ,settingLink} = this.context;
        return (
            <div className="showitems">
                <img src={Loader} alt="Loader Image" className={`search-loading ${loading ? 'show' : 'hide'}`}></img>
                <div className="showitemstop">
                    <Link to="/delivered_orders">    <ArrowBackSharpIcon className="arrowbackshow" /></Link>
                    <label className="shitmlbltop">Order Details</label>
                </div>

                <label className="checkout__tit">
                    Order Date :{order_date}
                </label>
                <label className="checkout__tit">
                    Order Number : {order_number}
                </label>
                <label className="checkout__tit">
                    Order Count :{order_count}
                </label>
                <label className="checkout__tit">
                    Total Amount {settingLink.CURRENCY} :{order_amount}
                </label>
                <div className="itemsorder">
                    <label className="itemsorderlbl">
                        ITEMS IN YOUR ORDER
                    </label>
                </div>
                {cartItems.map(item => (
                    <CheckoutProductord
                        description={item.description}
                        itemPrice={item.itemPrice}
                        quantity={item.quantity}
                        order_number={item.orderNo}
                        amount_value={item.amount_value}
                        price={item.price}
                        referenceNumber={item.reference_number}
                        item_code={item.item_code}
                        src={item.src}
                    />
                ))}



            </div>
        );
    }
}

export default ShowDeliveredItems;
