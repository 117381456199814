import React from "react";
import "./footerAd.css";
import { Link, withRouter } from "react-router-dom";
import { Linking } from "react";
import phone from "./phn.png";
import address from "./address.jpg";
import email from "./email.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import whatsapp from "./whatsapp.jpg";
import FloatingWhatsApp from "react-floating-whatsapp";
import "react-floating-whatsapp/dist/index.css";
import {
  faYoutube,
  faFacebook,
  faTwitter,
  faInstagram,
  faEmail,
  fa,
} from "@fortawesome/free-brands-svg-icons";
import { DataContext } from "./Context";
import { Icon, InlineIcon } from "@iconify/react";
import whatsappIcon from "@iconify-icons/mdi/whatsapp";
export class FooterAd extends React.Component {
  static contextType = DataContext;
  Capitalize = (name) => {
    return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
  };
  render() {
    const {
      CompanyName,
      settingLink,
      filterWithSubGroups,
      setHomeData,
      user,
      cartItems,
      SeachItems,
      groupsWithSubgroups,
      filterWithGroups,
    } = this.context;
    var companyname = "";
    var address = "";
    var city = "";
    var email = "";

    if (CompanyName[0] !== undefined) {
      companyname = CompanyName[0].FULL_NAME;
      address = CompanyName[0].ADDRESS;
      city = CompanyName[0].CITY;
      email = CompanyName[0].EMAIL_ADDRES;
    }
    return (
      <div
        className=""
        style={{ backgroundColor: settingLink.FOOTER_BG_COLOR }}
      >
        <div style={{ backgroundColor: settingLink.FOOTER_BG_COLOR }}>
          <div
            className="footer-gray"
            style={{ backgroundColor: settingLink.FOOTER_BG_COLOR }}
          >
            <div className="footer-custom">
              <div className="footer-lists">
                <div className="footer-list-wrap">
                  <h6
                    className="ftr-hdr"
                    style={{ color: settingLink.FOOTER_HEADING_TEXT_COLOR }}
                  >
                    Customer Service{" "}
                  </h6>{" "}
                  <ul className="ftr-links-sub">
                    <li>
                      <a
                        style={{ color: settingLink.FOOTER_TEXT_COLOR }}
                        onClick={() => this.props.history.push("/orderingInfo")}
                        rel="nofollow"
                      >
                        Ordering{" "}
                      </a>{" "}
                    </li>{" "}
                    <li>
                      <a
                        style={{ color: settingLink.FOOTER_TEXT_COLOR }}
                        onClick={() => this.props.history.push("/shippingInfo")}
                        rel="nofollow"
                      >
                        Shipping & amp; Delivery{" "}
                      </a>{" "}
                    </li>{" "}
                    <li>
                      <a
                        style={{ color: settingLink.FOOTER_TEXT_COLOR }}
                        onClick={() => this.props.history.push("/ReturnsInfo")}
                        rel="nofollow"
                      >
                        Returns{" "}
                      </a>{" "}
                    </li>{" "}
                  </ul>{" "}
                </div>{" "}
                <div className="footer-list-wrap">
                  <h6
                    className="ftr-hdr"
                    style={{ color: settingLink.FOOTER_HEADING_TEXT_COLOR }}
                  >
                    About Us{" "}
                  </h6>{" "}
                  <ul className="ftr-links-sub">
                    <li>
                      <a
                        style={{ color: settingLink.FOOTER_TEXT_COLOR }}
                        onClick={() => {
                          this.props.history.push("/aboutus");
                          window.scrollTo({ behavior: "smooth", top: "0px" });
                        }}
                        rel="nofollow"
                      >
                        Our Company{" "}
                      </a>{" "}
                    </li>{" "}
                  </ul>{" "}
                </div>{" "}
                <div className="footer-list-wrap">
                  <h6
                    className="ftr-hdr"
                    style={{ color: settingLink.FOOTER_HEADING_TEXT_COLOR }}
                  >
                    My Account{" "}
                  </h6>{" "}
                  <ul className="ftr-links-sub">
                    <li>
                      <a
                        style={{ color: settingLink.FOOTER_TEXT_COLOR }}
                        onClick={() =>
                          this.props.history.push("/shopping-cart")
                        }
                        rel="nofollow"
                      >
                        Cart{" "}
                      </a>{" "}
                    </li>{" "}
                    <li>
                      <a
                        style={{ color: settingLink.FOOTER_TEXT_COLOR }}
                        onClick={() => this.props.history.push("/orderItems")}
                        rel="nofollow"
                      >
                        Pending Orders{" "}
                      </a>{" "}
                    </li>{" "}
                    <li>
                      <a
                        style={{ color: settingLink.FOOTER_TEXT_COLOR }}
                        onClick={() =>
                          this.props.history.push("/delivered_orders")
                        }
                        rel="nofollow"
                      >
                        Approved Orders{" "}
                      </a>{" "}
                    </li>{" "}
                  </ul>{" "}
                </div>{" "}
              </div>{" "}
              <div className="footer-social">
                <h6
                  className="ftr-hdr"
                  style={{ color: settingLink.FOOTER_HEADING_TEXT_COLOR }}
                >
                  Social Media Links{" "}
                </h6>{" "}
                <ul>
                  <li>
                    <a
                      style={{ color: settingLink.FOOTER_TEXT_COLOR }}
                      href={settingLink.FACEBOOK_LINK}
                      title="Facebook"
                      onclick="_gaq.push(['_trackSocial', 'Facebook', 'Follow', 'Footer', 'undefined', 'True']);"
                    >
                      <img
                        width="24"
                        height="24"
                        alt="Like us on Facebook"
                        src="http://cache1.artprintimages.com/images/jump_pages/rebrand/footer/fb.png"
                      />
                    </a>{" "}
                  </li>{" "}
                  <li>
                    <a
                      style={{ color: settingLink.FOOTER_TEXT_COLOR }}
                      href={settingLink.WHATSAPP_LINK}
                      title="Whatsapp"
                    >
                      <img
                        width="24"
                        height="24"
                        alt="Whatsapp Lnk"
                        src={whatsapp}
                      />{" "}
                    </a>{" "}
                  </li>{" "}
                  <li>
                    <a
                      style={{ color: settingLink.FOOTER_TEXT_COLOR }}
                      title="Instagram"
                      href={settingLink.INSTAGRAM_LINK}
                    >
                      <img
                        width="24"
                        height="24"
                        alt="Follow us on Instagram"
                        src="http://cache1.artprintimages.com/images/jump_pages/rebrand/footer/instagram-badge.png"
                      />
                    </a>{" "}
                  </li>{" "}
                  <li>
                    <a
                      style={{ color: settingLink.FOOTER_TEXT_COLOR }}
                      href={settingLink.TWITTER_LINK}
                      title="Twitter"
                      onclick="_gaq.push(['_trackSocial', 'Twitter', 'Follow', 'Footer', 'undefined', 'True']);"
                    >
                      <img
                        width="67"
                        alt="Follow us on Twitter"
                        src="http://cache1.artprintimages.com/images/jump_pages/rebrand/footer/twitter.png"
                      />
                    </a>{" "}
                  </li>{" "}
                </ul>{" "}
              </div>{" "}
              <div className="footerAboutUs">
                {" "}
                <h6
                  className="ftr-hdr"
                  style={{ color: settingLink.FOOTER_HEADING_TEXT_COLOR }}
                >
                  Contact Us{" "}
                </h6>{" "}
                <ul className="ftr-links-sub">
                  <li style={{ color: settingLink.FOOTER_TEXT_COLOR }}>
                    <img className="footerAboutUsImg" src={phone} />
                    Phone: {settingLink.TELEPHONES_LINK}{" "}
                  </li>{" "}
                  <li
                    style={{ color: settingLink.FOOTER_TEXT_COLOR }}
                    onClick={() => {}}
                  >
                    <img className="footerAboutUsImg" src={email} />
                    Email: {settingLink.EMAIL_LINK}{" "}
                  </li>{" "}
                  <li style={{ color: settingLink.FOOTER_TEXT_COLOR }}>
                    <img className="footerAboutUsImg" src={address} />
                    Address: {settingLink.ADDRESS_LINK}{" "}
                  </li>{" "}
                </ul>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
        <FloatingWhatsApp
          className="floating-whatsapp"
          phoneNumber={settingLink.TELEPHONES_LINK}
          accountName={this.Capitalize(companyname)}
          statusMessage="Feel free to ask for products"
          chatMessage="Welcom how may we help  you?"
        />
      </div>
    );
  }
}

export default withRouter(FooterAd);
