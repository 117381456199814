import React, { Component } from 'react'
import './SearchResults.css'
import SearchProductIem from './SearchProductIem'
import { DataContext } from './Context'
import ReactPaginate from 'react-paginate';
import Loader from './loader.gif'
import { Link, withRouter } from 'react-router-dom'
export class SearchResults extends Component {
    static contextType = DataContext;
    State = {

    }
    Capitalize = (name) => {
        return name.replace(/\b(\w)/g, s => s.toUpperCase());
    }
    render() {
        const { sortFeatured, loading, filterWithGroups, sortPriceHighToLow, sortPriceLowToHigh, searchResults, searchedWord, changePageSearched, pageNumberSearched, itemsPerSearched, groupsWithSubgroups } = this.context;

        const pageVisited = pageNumberSearched * itemsPerSearched;
        const slicedData = pageVisited + itemsPerSearched;
        const pageCount = Math.ceil(searchResults.length / itemsPerSearched);
        return (
            <div className="search-results">
                <div className="headerdiv">
                    <img src={Loader} alt="Loader Image" className={`search-loading ${loading ? 'show' : 'hide'}`}></img>

                    <div><label>{searchResults.length} result(s) for </label><label> "{searchedWord}"</label></div>
                    <div className="sort-items">
                        <label>Sort By: </label>   <select onChange={(e) => {
                            const selectedGroup = e.target.value;
                            if (selectedGroup == "low") {
                                sortPriceLowToHigh(searchResults);
                            }
                            if (selectedGroup == "high") {
                                sortPriceHighToLow(searchResults);
                            }
                            if (selectedGroup == 'default') {
                                sortFeatured();
                            }
                        }}
                            className="header__select">
                            <option value="default">
                                Featured
                            </option>
                            <option value="low">
                                Price :Low to High
                            </option>
                            <option value="high">
                                Price :High to Low
                            </option>

                        </select>
                    </div>
                </div>
                <div className="search_page">
                    <div className="categoryPanel">
                        <label className="categoryPanelHeader">CATEGORY</label>
                        <div className="panelItems">{groupsWithSubgroups.map((item, index) => {
                            return (
                                <div className="divCatItm"> <label className="panelItemsLabel" onClick={() => {
                                    filterWithGroups(item.item_group);
                                    this.props.history.push('/product-categories');
                                }}>{this.Capitalize(item.group_name)}</label></div>

                            )
                        })}</div>
                    </div>
                    <div className="items-div">
                        {searchResults.slice(pageVisited, slicedData).map(product => (
                            < SearchProductIem product={product} key={product.item_code} />
                        ))}

                    </div>
                </div>
                <div className="pag">
                    <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        pageCount={pageCount}
                        onPageChange={changePageSearched}
                        containerClassName={"paginationBttns"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"paginationActive"}
                        disableInitialCallback={false}
                    />


                </div>
            </div>
        )
    }
}
export default withRouter(SearchResults);