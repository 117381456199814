import React, { Component } from 'react'
import { DataContext } from './Context'
import './Categories.css'

import Loader from './loader.gif'
import ReactPaginate from 'react-paginate';
import Itemgroups from './section/Itemgroups';
import { Link } from 'react-router-dom'
import CategoryItem from './CategoryItem'
import ItemsWithGroupsCaurosel from './section/ItemsWithGroupsCaurosel'
import CategoriesPopUp from './CategoriesPopUp'
import HeaderA from './HeaderA'
import NavBar from './NavBar';


export class Categories extends Component {
    static contextType = DataContext;
    state = {
        isOpen: false,
        sidebar: false,
        filterText: '',
        setting2: false
    }
    Capitalize = (name) => {
        return name.replace(/\b(\w)/g, s => s.toUpperCase());
    }
    togglePopup = () => {
        this.setState({
            isOpen: !this.state.isOpen,
            filterText: ''

        })
    }
    toggleHeader = () => {
        this.setState({
            setting2: !this.state.setting2,
            filterText: ''

        })
    }
    handleKeyPress = (event) => {
        this.setState({

            filterText: event.target.value.toString().toLowerCase()
        })

    }
    showNavBar = () => {

        this.setState({
            setting2: !this.state.setting2,
            sidebar: !this.state.sidebar
        })

    }
    removeSideBar = () => {
        this.setState({
            setting2: !this.state.setting2,
            sidebar: false
        })
    }
    render() {
        const { filterWithGroups, groupsWithSubgroups, subCategoryName, filterWithSubGroups, subgroups, itemGroupSelected, setItemCode, productsPage, itemGroupItems, addCart, loading, itemGroupName, pageNumberGroup, itemsPerPageGroup, changePageGroup } = this.context;
        const { isOpen, setting2, sidebar } = this.state;
        const { filterText } = this.state;
        const pageVisited = pageNumberGroup * itemsPerPageGroup;
        const slicedData = pageVisited + itemsPerPageGroup;
        const pageCount = Math.ceil(itemGroupItems.length / itemsPerPageGroup);

        return (
            <div className="Categories">
                <div className="">

                    
                </div>
                {/* <div className="categories_details">
                    <h2 className="categories_title">Categories</h2>
                    <Itemgroups />
                </div> */}
                {<div className="Categories_left" >

                    <div className="popupcategories"> <button
                        onClick={this.togglePopup} className="buttonCategories">
                        <span>Search Categories</span>
                    </button>
                        {isOpen && <CategoriesPopUp
                            content={<>
                                <input type="search" className="categoriesinput" onChange={this.handleKeyPress}></input>
                                <div>   {groupsWithSubgroups.filter(t => t.group_name.includes(filterText)).map(eacResult => {
                                    return (
                                        <div > <label onClick={() => {
                                            this.togglePopup();
                                            filterWithGroups(eacResult.item_group);

                                        }} className="main_category_header" key={eacResult.item_group} >
                                            {this.Capitalize(eacResult.group_name)}
                                        </label></div>

                                    )
                                })}</div>
                            </>}
                            handleClose={this.togglePopup}
                        />}
                    </div>
                    <div className="main_categories">



                    </div>
                    <div ><label className="item_group_name">{itemGroupName} {subCategoryName}</label></div>
                    <div className="sub"><p>{!isOpen && subgroups.map(eachSub => (
                        <label className="each_sub_c"
                            onClick={() => filterWithSubGroups(itemGroupSelected, `->${this.Capitalize(eachSub.sub_group_name)}`, eachSub.sub_group)}>{this.Capitalize(eachSub.sub_group_name + ">")}</label>
                    ))}</p></div>
                    <div id="product">

                        <img src={Loader} alt="Loader Image" className={`search-loading ${loading ? 'show' : 'hide'}`}></img>

                        {itemGroupItems.slice(pageVisited, slicedData).map(product => (
                            <div className="product_ps" key={product.item_code}>
                                <div className="product__info">
                                    <CategoryItem
                                        product={product}
                                    />

                                </div >

                            </div>
                        ))

                        }

                    </div>
                    <div >
                        <ReactPaginate
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            pageCount={pageCount}
                            onPageChange={changePageGroup}
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                            disableInitialCallback={false}
                        />


                    </div>

                    {!isOpen && <ItemsWithGroupsCaurosel />}


                </div>}
                {setting2 && <div className="Categories_left1" onClick={this.removeSideBar}>

                    <div className="main_categories">

                    </div>
                    <div ><label className="item_group_name">{itemGroupName} {subCategoryName}</label></div>
                    <div className="sub"><p>{!isOpen && subgroups.map(eachSub => (
                        <label className="each_sub_c"
                            onClick={() => filterWithSubGroups(itemGroupSelected, `->${this.Capitalize(eachSub.sub_group_name)}`, eachSub.sub_group)}>{this.Capitalize(eachSub.sub_group_name + ">")}</label>
                    ))}</p></div>
                    <div id="product">

                        <img src={Loader} alt="Loader Image" className={`search-loading ${loading ? 'show' : 'hide'}`}></img>

                        {
                            !isOpen && itemGroupItems.slice(pageVisited, slicedData).map(product => (
                                <div className="product_ps" key={product.item_code}>
                                    <div className="product__info">
                                        <CategoryItem
                                            product={product}
                                        />

                                    </div >

                                </div>
                            ))

                        }

                    </div>
                    <div >
                        <ReactPaginate
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            pageCount={pageCount}
                            onPageChange={changePageGroup}
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                            disableInitialCallback={false}
                        />


                    </div>

                    {!isOpen && <ItemsWithGroupsCaurosel />}


                </div>}

            </div>
        );
    }
}

export default Categories;
