import React, { Component } from 'react'
import './categoriesAd.css'
import { DataContext } from './Context'
import { Link } from 'react-router-dom'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default class CategoriesAd extends Component {
    static contextType = DataContext;
    state = {
        filterText: ''
    }
    handleKeyPress = (event) => {
        this.setState({
            filterText: event.target.value.toString().toLowerCase()
        })

    }
    Capitalize = (name) => {
        return name.replace(/\b(\w)/g, s => s.toUpperCase());
    }
    render() {
        const { groupsWithSubgroups, filterWithGroups } = this.context;
        const { filterText } = this.state;
        return (
            <div className="categoriesAd">
                <div className="classLeft">
                    <div className="cat-layout">  <input type="search" placeholder="Search..." className="cat-search" onChange={this.handleKeyPress}></input>
                    </div>   <div className="accordions"> {groupsWithSubgroups.filter(t => t.group_name.includes(filterText)).map((item, index) => {
                        return (
                            <div  >
                                <li key={index} className='nav-text' onClick={(() => {
                                    filterWithGroups(item.item_group);
                                })}> </li>

                                <Accordion className="accordionItem">
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"

                                    >
                                        <Typography >{this.Capitalize(item.group_name)}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {/* {item.sub_groups.map((group, index) => {
                                            <Typography>
                                                {group.sub_group_name}
                                            </Typography>
                                        })} */}
                                        <Typography>

                                        </Typography>
                                    </AccordionDetails>
                                </Accordion> </div>
                        )
                    })}</div>
                </div>
                <div className="classRight">
                </div>

            </div>
        )
    }
}
