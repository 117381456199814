import React from 'react'
import './Checkout.css'

function Checkout() {
    return (
        <div className="checkout">
            adldkla
        </div>
    )
}

export default Checkout
