import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom'
import SearchIcon from '@material-ui/icons/Search';
import { DataContext } from './Context';
import axios from 'axios'
import './headerMob.css'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import * as FaIcons from 'react-icons/fa';

class HeaderMob extends Component {
    static contextType = DataContext;
    state = {
        query: '',
        logo: ''
    };
    componentDidMount() {
        { this.getLogo() }
    }
    getLogo = () => {
        const { db, ip, port, dbuser, dbpass, url } = this.context;
        axios
            .get(url + "getImageLogo", {
                headers: {
                    "Content-Type": "application/octet-stream",
                    Accept: "application/octet-stream",
                },
                responseType: "blob",
                params: {
                    ip: ip,
                    db: db,
                    dbuser: dbuser,
                    dbpass: dbpass,
                    port: port
                }
            })
            .then((response) => {

                this.setState({ logo: URL.createObjectURL(response.data), isLogofied: true })
            })
            .catch((error) => {

                console.log(error);

            });
    }
    render() {
        const { query } = this.state;
        const { settingLink, cartItems } = this.context;
        return (
            <div className="headerMob">
                <div className="toppartmob">
                    {/* <img className="imgMob" src={this.stat e.logo} /> */}
                    <div className="logosearchmob">
                        <FaIcons.FaBars size="25px" style={{ color: 'black', cursor: 'pointer', marginLeft: "10px" }} onClick={() => { this.showNavBar() }} />
                        <div className="wrapmob">
                            <div className="searchmob">
                                <input type="text" className="searchTermmob"
                                    onChange={this.handleOnInputChange}
                                    onKeyPress={this.handleKeyPress}
                                    placeholder="Search For Products" />
                                <button type="submit" className="searchButtonmob">
                                    <Link to="/search-results">
                                        <SearchIcon ></SearchIcon>
                                    </Link>
                                </button>
                            </div>

                        </div>
                        <Link to="/shopping-cart" style={{ textDecoration: "none" }}>
                            <div className="header_optionBasket" >
                                <ShoppingBasketIcon style={{ color: 'black' }} />
                                <span className="mobcartsize" >{cartItems.length}</span>
                            </div>
                        </Link>

                    </div>
                </div>
            </div>
        );
    }
}

export default HeaderMob;