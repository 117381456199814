import React, { Component } from 'react'
import './SearchProductItem.css';
import { DataContext } from './Context'
import defaultimage from './defaultimg.png'
import axios from 'axios'
import { Link, withRouter } from 'react-router-dom'
import ReactStars from "react-rating-stars-component";
import NumberFormat from 'react-number-format';


export class SearchProductIem extends Component {
    static contextType = DataContext;
    state = {
        image: defaultimage,
        imageHash: Date.now()
    };
    fetchImageBytes(imageName) {
        const { url, ip, db, dbuser } = this.context;
        if (imageName !== null && imageName !== undefined) {
            axios
                .get(url + "getImage", {
                    headers: {
                        "Content-Type": "application/octet-stream",
                        Accept: "application/octet-stream",
                    },
                    responseType: "blob",
                    params: {
                        name: imageName,
                        ip: ip,
                        db: db,
                        dbuser: dbuser
                    }
                })
                .then((res) => {
                    if (res !== null && res !== undefined) {
                        if (res.status === 200) {
                            const blob = res.data;
                            const url = URL.createObjectURL(blob);
                            this.setState({
                                image: url,
                            });
                            return url;
                        } else {
                            this.setState({
                                image: defaultimage,
                            });
                            return defaultimage;
                        }
                    } else {
                        this.setState({
                            image: defaultimage,
                        });
                        return defaultimage;
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error response: " + error.response.status);
                    } else if (error.request) {
                        console.log("error request: " + error.request);
                    } else {
                        console.log("error: " + error.message);
                    }
                    this.setState({
                        image: defaultimage,
                    });
                    return defaultimage;
                });
        }
    }
    componentDidMount() {
        this.fetchImageBytes(this.props.product.src[0]);
    }
    percentageoff = (val1, val2) => {
        var val3 = (val1 / val2) * 100;
        val3 = 100 - val3;
        return Number.parseFloat(-val3).toFixed(0);
    }
    render() {
        const { setItemCode, settingLink, addCart } = this.context;
        return (
            <div className="each-search-result" >
                <div className="each-search-resultP1" onClick={() => {
                    this.props.history.push('/product');
                    setItemCode(this.props.product.item_code);
                }} >
                    {this.props.product.promotion_price > 0 && <div className="promotionsdiv" style={{ backgroundColor: settingLink.NAVBAR_COLOR }}>
                        <label style={{ color: settingLink.NAVBAR_TXT_COLOR }}>{this.percentageoff(this.props.product.promotion_price, this.props.product.itemPrice)}%</label></div>}

                    <div> <Link to="/product"> <img className="image1"
                        src={this.state.image} onClick={() => setItemCode(this.props.product.item_code)}></img></Link></div>
                    <div className="product-details">
                        <label className="lblItemName">{this.props.product.item_name}</label>
                        <label className="lblItemCode">{this.props.product.item_code}</label>
                        <div className="cartsimilarstars">
                            <ReactStars
                                edit={false}
                                count={5}
                                // onChange={ratingChanged}
                                size={20}
                                color={"#ffd700"}
                                activeColor="#ffd700"
                            />
                        </div>

                    </div>

                </div>
                <div className="product-pricing">
                    <div className="pricesdiv">
                        {this.props.product.promotion_price < 1 && <label className="lblPrice">
                            <small>KES </small>
                            <strong>{this.props.product.price}</strong></label>}
                        {this.props.product.promotion_price > 1 && <label className="lblPrice">
                            <small>KES </small>
                            <strong> <NumberFormat className="lb"
                                value={this.props.product.promotion_price} displayType={'text'} thousandSeparator={true} prefix={''} /></strong></label>}
                        {this.props.product.promotion_price > 0 &&
                            <label className="promolbl">KES : {this.props.product.price}</label>}
                    </div>
                    <button onClick={() => addCart(this.props.product)} className="btnAddtocart" style={{ color: settingLink.NAVBAR_TXT_COLOR, backgroundColor: settingLink.NAVBAR_COLOR }}>Add To Cart</button>
                </div>
            </div>
        )
    }
}
export default withRouter(SearchProductIem);