import React, { Component } from 'react'
import './Shipping.css'
import { DataContext } from './Context'

export default class Shipping extends Component {
    static contextType = DataContext;

    render() {
        const { settingLink } = this.context;
        return (
            <div className="mainp">
                <p>{settingLink.WEB_SHIPPING_INFO}</p>

            </div>
        )
    }
}
