import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom'
import { DataContext } from './Context';
import './categorieson.css'
import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';


class Categorieson extends Component {
    static contextType = DataContext;
    state = {
        showsub: false,
        subgroups: [],
        item_group: '',
        group_name: ''
    };
    toggleSub = (eac) => {

        this.setState({
            showsub: true, subgroups: eac.subgroups,
            item_group: eac.item_group, group_name: eac.group_name
        })
    }

    Capitalize = (name) => {
        return name.replace(/\b(\w)/g, s => s.toUpperCase());
    }
    retNormal = () => {
        this.setState({ showsub: false })
    }
    render() {
        const { groupsWithSubgroups, filterWithGroups, filterWithSubGroups } = this.context
        const { showsub, subgroups } = this.state;
        return (
            <div className="ee" onMouseLeave={() => { this.retNormal() }}>
                <div className="cmaindiv">
                    <div className="cdivLef">
                        {groupsWithSubgroups.slice(0, 10).map(
                            (eachgroup) => {
                                return (
                                    <div className="ediv" onMouseOver={() => { this.toggleSub(eachgroup) }} >
                                        <label>{this.Capitalize(eachgroup.group_name)}</label>
                                        <KeyboardArrowRightSharpIcon style={{ fontSize: '30px' }}></KeyboardArrowRightSharpIcon>

                                    </div>
                                )
                            }
                        )}
                        <div className="ediv" onMouseOver={() => { this.setState({ showsub: false }) }}
                            onClick={() => {
                                this.props.history.push('/product-categories')
                                window.scrollTo(500, 0);
                            }}>
                            <label>{this.Capitalize("Other Categories")}</label>
                            <KeyboardArrowRightSharpIcon style={{ fontSize: '30px' }}></KeyboardArrowRightSharpIcon>

                        </div>
                    </div>
                    <div className="cdivRight" >
                        {showsub && <div className="ediv2">
                            <label onClick={() => {
                                filterWithGroups(this.state.item_group)
                                this.props.history.push('/product-categories')
                            }}>{this.Capitalize('All')}</label>
                        </div>}
                        {showsub && subgroups.slice(0, 10).map(
                            (eachgroup) => {
                                return (
                                    <div className="ediv2">
                                        <label
                                            onClick={() => {
                                                filterWithSubGroups(this.state.item_group, `${this.Capitalize(this.state.group_name)}->${this.Capitalize(eachgroup.sub_group_name)}`, eachgroup.sub_group)
                                                this.props.history.push('/product-categories')
                                            }}
                                        >{this.Capitalize(eachgroup.sub_group_name)}</label>

                                    </div>
                                )
                            }
                        )}

                    </div>
                </div></div>
        );
    }
}

export default withRouter(Categorieson);