import React, { Component } from 'react'
import { DataContext } from './Context'
import './CheckoutC.css'
import axios from 'axios';
import Loader from './loader.gif'
import { Link } from 'react-router-dom'
import Subtotal from './Subtotal'
import CheckoutProduct from './CheckoutProduct'

export class CheckoutC extends Component {
    static contextType = DataContext;
    state = {
        imgSrc: ''
    }
    componentDidMount() {

    }
    getBanner = (bannerName) => {
        const { url, ip, db, dbuser } = this.context;
        axios
            .get(url + "getBannerImage", {
                headers: {
                    "Content-Type": "application/octet-stream",
                    Accept: "application/octet-stream",
                },
                responseType: "blob",
                params: {
                    name: bannerName,
                    ip: ip,
                    db: db,
                    dbuser: dbuser
                }
            })
            .then((response) => {

                this.setState({ imgSrc: URL.createObjectURL(response.data) })
            })
            .catch((error) => {

                console.log(error);

            });
    }
    render() {
        const { cartItems,settingLink } = this.context;
        const { imgSrc } = this.state;
        if (settingLink.WEB_SITE_LOGO !== undefined) {
            this.getBanner(settingLink.WEB_SITE_BANNER);
        }
        return (
            <div className="checkout">
                <div className="checkout__left">
                    <img className="checkout__ad"
                        src={imgSrc} alt="" />
                    <div>
                        <h2 className="checkout__title">
                            Your Shopping List
                        </h2>
                        {cartItems.map(item => (
                            <CheckoutProduct key={item.item_code}
                                description={item.description}
                                itemPrice={item.itemPrice}
                                quantity={item.quantity}
                                order_number={item.orderNo}
                                amount_value={item.amount_value}
                                price={item.price}
                                referenceNumber={item.reference_number}
                                item_code={item.item_code}
                                src={item.src}
                            />
                        ))}
                    </div>
                </div>
                <div className="checkout__right">
                    <Subtotal />
                </div>
            </div>
        );
    }
}

export default CheckoutC;
