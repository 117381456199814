import React, { Component } from 'react'
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import defaultimage from './defaultimg.png'
import './mobimageslider.css'
import { DataContext } from './Context';
import axios from 'axios'

export class MobImageSlider extends Component {
    static contextType = DataContext;

    state = {
        windowAllow: false,
        sliderLoaded: false,
        SliderData: [],
        settingLink: {}
    }

    fetchSliderNames = () => {
        const { db, ip, port, dbuser, dbpass, url } = this.context;
        axios
            .get(url + 'getSlidersNames', {
                params: {
                    ip: ip,
                    db: db,
                    port: port,
                    dbuser: dbuser,
                    dbpass: dbpass
                }
            })
            .then((response) => {

                { this.createSliderSliders(response.data.list.WEB_SITE_SLIDERS) }
            }).catch((error) => {

                console.log(error);
            });
    }
    createSliderSliders = (slides) => {
        console.log("ddjdkjkdl" + slides)
        const { db, ip, dbuser, dbpass, url } = this.context;
        var sliderAddedData = [];
        this.setState({ sliderLoaded: true })
        slides.forEach((slide => {
            var imageName = slide.imageName;
            console.log(imageName);
            if (imageName !== null && imageName !== undefined) {
                axios
                    .get(url + "getSliders", {
                        headers: {
                            "Content-Type": "application/octet-stream",
                            Accept: "application/octet-stream",
                        },
                        responseType: "blob",
                        params: {
                            name: imageName,
                            ip: ip,
                            db: db,
                            dbuser: dbuser
                        }
                    })
                    .then((res) => {
                        if (res !== null && res !== undefined) {
                            if (res.status === 200) {
                                const blob = res.data;
                                const url = URL.createObjectURL(blob);
                                const obj = {
                                    'linkCode': slide.linkCode,
                                    'imageName': slide.imageName,
                                    'linkDescription': slide.linkDescription,
                                    'linkType': slide.linkType,
                                    'url': url
                                };
                                sliderAddedData.push(obj);
                                this.setState({
                                    SliderData: [...this.state.SliderData, obj],
                                })
                            } else {


                            }
                        } else {

                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            console.log("error response: " + error.response.status);
                        } else if (error.request) {
                            console.log("error request: " + error.request);
                        } else {
                            console.log("error: " + error.message);
                        }

                    });
            }
        }));
    }
    componentDidMount() {
        { this.fetchSliderNames() }
    }
    render() {
        const { SliderData } = this.state;
        return (
            <div className="mobileSliders">
                <AliceCarousel autoPlay autoPlayInterval="3000">
                    {SliderData.map((eachSlide) => {
                        return (
                            <div className="">
                                <img src={eachSlide.url} className="sliderimg" />
                            </div>
                        )
                    })}

                </AliceCarousel>

            </div>
        )
    }
}
export default MobImageSlider;