import React, { Component } from 'react'
import './cartitm.css'
import defaultimage from './defaultimg.png'
import { DataContext } from './Context'
import axios from 'axios'
import Loader from './loader.gif'
import NumberFormat from 'react-number-format';
import ReactStars from "react-rating-stars-component";
export class CartItemComp extends Component {
    static contextType = DataContext;
    state = {
        image: defaultimage,
    };
    fetchImageBytes(imageName) {
        const { url, ip, db, dbuser } = this.context;
        if (imageName !== null && imageName !== undefined) {
            axios
                .get(url + "getImage", {
                    headers: {
                        "Content-Type": "application/octet-stream",
                        Accept: "application/octet-stream",
                    },
                    responseType: "blob",
                    params: {
                        name: imageName,
                        ip: ip,
                        db: db,
                        dbuser: dbuser
                    }
                })
                .then((res) => {
                    if (res !== null && res !== undefined) {
                        if (res.status === 200) {
                            const blob = res.data;
                            const url = URL.createObjectURL(blob);
                            console.log("url: " + url);
                            this.setState({
                                image: url,
                            });
                            return url;
                        } else {
                            console.log(res.statusText);
                            this.setState({
                                image: defaultimage,
                            });
                            return defaultimage;
                        }
                    } else {
                        this.setState({
                            image: defaultimage,
                        });
                        return defaultimage;
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error response: " + error.response.status);
                    } else if (error.request) {
                        console.log("error request: " + error.request);
                    } else {
                        console.log("error: " + error.message);
                    }
                    this.setState({
                        image: defaultimage,
                    });
                    return defaultimage;
                });
        }
    }
    Capitalize = (name) => {
        return name.replace(/\b(\w)/g, s => s.toUpperCase());
    }
    componentDidMount() {
        this.fetchImageBytes(this.props.src[0]);
    }

    render() {
        const { changeQuantity, removeFromCart, loading ,settingLink} = this.context;
        return (
            <div className="maincartdiv">
                <img src={Loader} alt="Loader Image" className={`search-loading ${loading ? 'show' : 'hide'}`}></img>
                <div className="imganddetails">
                    <img className="prdimgCss" src={this.state.image} ></img>
                    <div className="detailspro">
                        <div> <label className="lblh">{this.props.description}</label>
                            <div className="cartsimilarstars">
                                <ReactStars
                                    edit={false}
                                    count={5}
                                    // onChange={ratingChanged}
                                    size={24}
                                    color={"#ffd700"}
                                    activeColor="#ffd700"
                                />
                                <div className="itemprelisquits">
                                    <div className="rediced">
                                        <label className="lvlh">Item Code :</label>
                                        <label className="lvlhsub">{this.props.item_code.toLowerCase()}</label>
                                    </div>
                                    {this.props.color !== undefined && this.props.color.length > 1 && <div classname="rediced">
                                        <label className="lvlh">Color :</label>
                                        <label className="lvlhsub">{this.Capitalize(this.props.color)}</label>
                                    </div>}

                                    {this.props.brand !== undefined && this.props.brand.length > 1 && <div classname="rediced">
                                        <label className="lvlh">Brand :</label>
                                        <label className="lvlhsub"> {this.Capitalize(this.props.brand)}</label>
                                    </div>}


                                </div>
                            </div>
                        </div>
                        <div className="blayout">
                            <div className="divQuantity"><input onChange={(e) =>
                                changeQuantity(e, this.props.order_number, this.props.referenceNumber,
                                    this.props.item_code, this.props.quantity, this.props.itemPrice)} className="checkoutqtinp" type='number' defaultValue={this.props.quantity}></input>
                            </div>
                            <div className="divDelete">
                                <label className="lbldelete" onClick={() => removeFromCart(this.props.order_number, this.props.referenceNumber, this.props.item_code)}>Remove From Cart</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="divsright">
                    <label className="llblprice" >{settingLink.CURRENCY}{this.props.price}</label>
                    <div className="amountvalue">
                        <label className="" >Total</label>
                        <NumberFormat className="lblamountvalue"  value={this.props.amount_value} displayType={'text'} thousandSeparator={true} prefix={settingLink.CURRENCY} />
                        {/* <label className="lblamountvalue" >KES { Number.parseFloat(this.props.amount_value).toFixed(2)}</label> */}
                    </div>
                </div>
            </div>
        )
    }
}
export default CartItemComp