import React, { Component } from 'react';
import './afterheaderb.css'
import * as FaIcons from 'react-icons/fa'
import {
    DataContext
} from './Context';
import { Link, withRouter } from 'react-router-dom'

class AfterHeaderb extends Component {
    static contextType = DataContext;
    state = {};

    render() {
        const { settingLink, setHomeData } = this.context;
        return (
            <div className="header_bts" style={{ backgroundColor: settingLink.NAVBAR_COLOR }}>
                <Link to="/product-categories" style={{ textDecoration: "none" }}> <label className="labelHeader" style={{ color: settingLink.NAVBAR_TXT_COLOR }} variant="outlined" > Product & Services</label></Link>
                <Link to="/Promotions" style={{ textDecoration: "none", color: settingLink.NAVBAR_TXT_COLOR }}> <label className="labelHeader" onClick={setHomeData} style={{ color: settingLink.NAVBAR_TXT_COLOR }} variant="outlined" > Todays Deals</label></Link>
                <Link to="/topselligitems" style={{ textDecoration: "none", color: settingLink.NAVBAR_TXT_COLOR }}> <label className="labelHeader" style={{ color: settingLink.NAVBAR_TXT_COLOR }} variant="outlined" > Top Selling Items </label></Link>

            </div>
        );
    }
}

export default AfterHeaderb;