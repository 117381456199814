import React, { Component } from 'react'
import { DataContext } from './Context'

export default class Ordering extends Component {
    static contextType = DataContext;
    render() {
        const { settingLink } = this.context;
        return (
            <div className="mainp">
                <p>{settingLink.WEB_ORDERING_INFO}</p>
            </div>
        )
    }
}
