import './Header.css';
import React, { Component } from 'react'
import { DataContext } from './Context'
import { Button } from '@material-ui/core';
import Combobox from 'react-widgets/lib/Combobox'
import { Link, withRouter } from 'react-router-dom'
import './login.css'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { auth } from './firebase'
import axios from 'axios'

export class LoginC extends Component {
    static contextType = DataContext;
    constructor(props) {
        super(props);
    }

    handleEmailChange = (e) => {
        this.setState({ email: e.target.value })
    };
    handlePasswordChange = (e) => {
        this.setState({ password: e.target.value })
        if (e.key === 'Enter') {
            const { setUser } = this.context;
            const { email, password } = this.state;
            auth.signInWithEmailAndPassword(email, e.target.value).then(auth => {
                setUser(email);
                this.props.history.push('/');

            }).catch(err =>
                alert(err.message))
        }
    };
    state = {
        email: '',
        password: '',
        checkSuccess: 'LoginC',
        logo: '',
        isLogofied: false
    }
    getLogo = (name) => {
        const { url, ip, db, dbuser } = this.context;
        axios
            .get(url + "getImageLogo", {
                headers: {
                    "Content-Type": "application/octet-stream",
                    Accept: "application/octet-stream",
                },
                responseType: "blob",
                params: {
                    name: name,
                    ip: ip,
                    db: db,
                    dbuser: dbuser
                }
            })
            .then((response) => {

                this.setState({ logo: URL.createObjectURL(response.data),isLogofied:true })
            })
            .catch((error) => {

                console.log(error);

            });
    }

    signIn = (e) => {
        e.preventDefault();
        toast.configure();
        const { setUser } = this.context;
        const { email, password } = this.state;
        auth.signInWithEmailAndPassword(email, password).then(auth => {
            setUser(email);

            this.props.history.push('/');

        }).catch(err =>

            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1500
            }));

    }
    render() {
        const { email, password, checkSuccess, isLogofied } = this.state;
        const{settingLink} = this.context;
        if (settingLink.WEB_SITE_LOGO !== undefined && !isLogofied) {
            this.getLogo(settingLink.WEB_SITE_LOGO);
        }
        return (
            <div className="login" >
                <Link to='/'>
                    <img className="login__logo" src={this.state.logo}></img></Link>
                <div className="login__container">
                    <h1>Sign-in</h1>
                    <form>
                        <h5>E-mail</h5>
                        <input value={email} onChange={this.handleEmailChange}
                            type="text"></input>

                        <h5>Password</h5>
                        <input value={password} onChange={this.handlePasswordChange}
                            type="password"></input>
                        <button className="login__signButton" onClick={this.signIn}>Sign In</button>
                    </form>
                    <p>By signing-in you agree to the term and Conditions of use & sales Please See Our Privacy Policy ,our cookies Notice and our Interest-Bases Ads Notice</p>
                    <Link to="/Register"> <button className="login__registerButton">Create an Account</button></Link>
                </div>
            </div>


        )

    }
}
export default withRouter(LoginC)
