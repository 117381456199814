import React, { Component } from 'react'
import Menu from './svg/bars-solid.svg'
import Close from './svg/times-solid.svg'
import CartIcon from './svg/shopping-cart-solid.svg'
import { Link } from 'react-router-dom'
import './css/Header.css'
import logo from "./chawaka logo.png";
import { DataContext } from './Context'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CarouselContainer from './CarouselContainer';

export class Header extends Component {
    static contextType = DataContext;

    componentDidMount() {
        window.scrollTo(0, 0);

    }

    componentDidUpdate() {
        window.scrollTo(0, 0);
    };

    state = {
              query: '',
        loading: false,
        message: '',

    }

    renderCompanyName = () => {
        const { CompanyName } = this.context;
        if (Object.keys(CompanyName).length && CompanyName.length) {
            CompanyName.map(eacResult => {
                const name = eacResult.FULL_NAME
                this.setState({
                    fullName: name

                })
            })

        }
    }

    buttonStyle = {
        margin: '10px 10px 10px 0'
    };
    menuToggle = () => {
        this.setState({ toggle: !this.state.toggle })
    }

    renderItemGroups = () => {

        const { filterWithGroups, itemGroups } = this.context;

        if (Object.keys(itemGroups).length && itemGroups.length) {
            return (
                <Accordion style={{ padding: "10px", margin: "10px" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography >CATEGORIES</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                            {itemGroups.map(eacResult => {
                                return (
                                    <grid key={eacResult.ITEM_GROUP}>
                                        <Button style={{ padding: "10px", margin: "3px", borderRadius: "19px", color: "teal" }}
                                            onClick={() => filterWithGroups(eacResult.ITEM_GROUP, eacResult.GROUP_NAME)} >{eacResult.GROUP_NAME}</Button>
                                        {'   '}
                                    </grid>

                                )
                            })}
                        </div>
                    </AccordionDetails>
                </Accordion>

            )
        }
    }
    handleOnInputChange = (event) => {
        const query = event.target.value;
        this.setState({ query: query }, () => {

        });
    };
    render() {
        const { toggle, query } = this.state;
        const { cart, SeachItems, fullName, itemGroupName, removeGroupNameDisplay } = this.context;

        return (
            <div>
                <header>
                    <div className="menu" onClick={this.menuToggle}>
                        <img src={Menu} alt="" width="20" />
                    </div>
                    <div >
                        <img src={logo} alt="" width="100px" />
                    </div>
                    <div className="logo">
                        <h1><Link to="/">{fullName}</Link></h1>
                    </div>
                    <nav>
                        <ul className={toggle ? "toggle" : ""}>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/product">Product</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                            <li><Link to="/about">About</Link></li>
                            <li><Link to="/login">Login / Register</Link></li>
                            <li className="close" onClick={this.menuToggle}>
                                <img src={Close} alt="" width="20" />
                            </li>
                        </ul>
                        <div className="nav-cart">
                            <span>{cart.length}</span>
                            <Link to="/cart" onClick={() => removeGroupNameDisplay()}>
                                <img src={CartIcon} alt="" width="20" />

                            </Link>
                        </div>
                    </nav>

                </header>
                <div style={{ margin: "auto", display:"flex"}}>
                    <input onChange={this.handleOnInputChange}
                        placeholder="Type To Search Items....." style={{margin:"3px" , width: "570px", padding: "10px", fontSize: "15px" }} />
                    {'    '} <Button variant="contained" onClick={() => SeachItems({ query })}
                        style={{padding: "5px", borderRadius: "10px", color: "teal" }}>Search </Button>
                    <label style={{ padding: "10px"}}>{itemGroupName}</label>
                </div>
                <div >
                    {this.renderItemGroups()}
                </div>

            </div >

        )
    }
}

export default Header
