import React, { Component } from 'react';
import { DataContext } from './Context'
import './navbarside.css';
import { Link, withRouter } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar';
import user from './user.png'
import xicon from './x.png'
import CloseIcon from '@material-ui/icons/Close';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';

export class SideBarNav extends Component {
    static contextType = DataContext;
    state = {
        subgroups: [],
        item_group: '',
        subgroupvisible: false,
        item_group_name: ''
    }

    Capitalize = (name) => {
        return name.replace(/\b(\w)/g, s => s.toUpperCase());
    }
    render() {
        const { settingLink, groupsWithSubgroups, filterWithSubGroups,filterWithGroups } = this.context;
        return (
            <div className="navbarside">
                <div className="navbar_left">
                    <div className="header_part" style={{ backgroundColor: settingLink.NAVBAR_COLOR }}>
                        <p className="header_partlable">Hello,{this.props.user}</p>
                        <div className="clsDiv"> <CloseIcon onClick={() => {
                            this.props.methodclose();
                            this.setState({ subgroupvisible: false });
                        }} className="x_icon" ></CloseIcon></div>

                    </div>
                    <div className="cat_below_header">
                        {!this.state.subgroupvisible && groupsWithSubgroups.map((item, index) => {
                            return (
                                <div className="cat_item" onClick={() => {
                                    this.setState({
                                        subgroups: item.subgroups,
                                        subgroupvisible: true,
                                        item_group: item.item_group,
                                        item_group_name: item.group_name
                                    })

                                    console.log("sub" + item.subgroups);
                                }}>
                                    <label>{this.Capitalize(item.group_name)}</label>
                                    <KeyboardArrowRightSharpIcon></KeyboardArrowRightSharpIcon>
                                </div>
                            )
                        })}
                        {this.state.subgroupvisible && <div className="cat_back" onClick={() => {
                            this.setState({ subgroupvisible: false })
                        }}>
                            <ArrowBackSharpIcon ></ArrowBackSharpIcon>
                            <label>MAIN MENU</label>

                        </div>}
                        {this.state.subgroupvisible && <div className="cat_item"
                            onClick={() => {
                                filterWithGroups(this.state.item_group)
                                this.props.history.push('/product-categories');
                                this.props.methodclose();
                                this.setState({ subgroupvisible: false })
                            }}
                        >
                            <label className=""
                            >{this.Capitalize('All')}</label>

                        </div>}

                        {this.state.subgroupvisible && this.state.subgroups.map((group, index) => {
                            return (
                                <div>

                                    <div className="cat_item"
                                        onClick={() => {
                                            filterWithSubGroups(this.state.item_group, `${this.Capitalize(this.state.item_group_name)}->${this.Capitalize(group.sub_group_name)}`, group.sub_group)
                                            this.props.history.push('/product-categories');
                                            this.props.methodclose();
                                            this.setState({ subgroupvisible: false })
                                        }}
                                    >
                                        <label className=""
                                        >{this.Capitalize(group.sub_group_name)}</label>

                                    </div>
                                </div>

                            )

                        })}
                    </div>
                </div>
                <div className="navbar_right" onClick={this.props.methodclose}>

                </div>
            </div>
        );
    }
}
export default withRouter(SideBarNav)