import React, { Component } from 'react'
import { DataContext } from './Context';
import './modproductList.css'
import ModProductListComponent from './ModProductListComponent';



export default class ModProductList extends Component {
    static contextType = DataContext;
    render() {
        const { products } = this.context;
        return (
            <div className="modproductList">
                {
                    products.map(product => (

                        <ModProductListComponent
                            product={product}
                        />

                    ))
                }
            </div>
        )
    }
}


