import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, withRouter } from "react-router-dom";

export const DataContext = React.createContext();

export class DataProvider extends Component {
  state = {
    products: [],
    productForGroups: [],
    cart: [],
    total: 0,
    loading: false,
    CompanyName: {},
    itemGroups: {},
    groupscopy: {},
    fullName: "",
    itemsGroupsCopy: {},
    itemGroupName: "All",
    itemGroupItems: {},
    pageNumber: 0,
    itemsPerPage: 12,
    pageNumberGroup: 0,
    itemsPerPageGroup: 15,
    pageNumberSearched: 0,
    itemsPerSearched: 10,
    pageNumberGroupd: 0,
    itemsPerPageGroupd: 15,
    displayItems: {},
    totolResults: 0,
    offset: 0,
    perPage: 12,
    currentPage: 0,
    itemsWithGroups: [],
    groupsWithSubgroups: [],
    companyLocation: [],
    itemGroupCode: "",
    fullItemDetails: [],
    itemCode: "",
    cartItems: [],
    cartTotal: 0,
    user: "",
    order_number: -1,
    order_count: 0,
    order_amount: 0,
    subgroups: [],
    itemGroupSelected: "",
    // ip: '62.12.116.180',
    // db: 'sirgil',
    // port: 50009,
    // dbuser: 'sirgil',
    // dbpass: 'sirgilEnt',
    // ip: '62.12.114.97',
    // db: 'gintodb',
    // port: 50007,
    // dbuser: 'ginto',
    // dbpass: 'gintodb.2020',
    ip: "62.12.116.180",
    db: "oneworld",
    port: 50002,
    dbuser: "oneworld",
    dbpass: "oneworlddb.2021",
    //  ip: '62.12.116.179',
    //  db: 'stagemat',
    //  port: 50008,
    //  dbuser: 'stagemat',
    //  dbpass: 'stagematt.2021',

    product: [],
    settingLink: {},
    order_type: "CSO",
    order_date: "",
    subCategoryName: "",
     url: 'https://62.12.113.90:8181/posJsonService/links/POS/',
 //   url: "https://ecommerce.ke/posJsonService/links/POS/",
    homeData: [],
    searchResults: [],
    searchedWord: "",
    cpSearchResults: [],
  };
  setHomeData = () => {
    const { homeData } = this.state;
    this.setState({
      products: homeData,
      pageNumber: 0,
      itemsPerPage: 12,
    });
  };
  searchWithBrandsandColors = (itemGroup, brandArray, colorArray) => {
    const reducedColor = colorArray.reduce((acc, curr) => `${acc}${curr},`, "");
    const reducedBrand = brandArray.reduce((acc, curr) => `${acc}${curr},`, "");
    const { db, ip, port, dbuser, dbpass, url, groupsWithSubgroups } =
      this.state;
    this.setState({ itemGroupName: "All" });
    groupsWithSubgroups.forEach((each_group) => {
      if (each_group.item_group == itemGroup) {
        this.setState({
          itemGroupName: this.Capitalize(each_group.group_name),
          subgroups: each_group.subgroups,
        });
      }
    });
    window.scrollTo({ behavior: "smooth", top: "0px" });
    this.setState({
      loading: true,
      subCategoryName: "",
      itemGroupSelected: itemGroup,
      pageNumberGroup: 0,
      itemsPerPageGroup: 15,
      itemGroupCode: itemGroup,
    });
    axios
      .get(url + "filterWithBrandsAndColors", {
        params: {
          group_code: itemGroup,
          brands: reducedBrand,
          colors: reducedColor,
          ip: ip,
          db: db,
          port: port,
          dbuser: dbuser,
          dbpass: dbpass,
        },
      })
      .then((response) => {
        this.setState({
          itemGroupItems: response.data.list,
          productForGroups: response.data.list,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  setUser = (usr) => {
    const { db, ip, port, dbuser, dbpass, url } = this.state;
    localStorage.setItem("user", JSON.stringify(usr));
    this.setState({
      user: usr,
    });

    axios
      .get(url + "fetchCartItems", {
        params: {
          WEB_USER: usr,
          ip: ip,
          db: db,
          port: port,
          dbuser: dbuser,
          dbpass: dbpass,
        },
      })
      .then((response) => {
        this.setState({
          loading: false,
          cartItems: response.data.list,
        });
        const { cartItems } = this.state;
        {
          this.getTotalPrice(cartItems);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  completeOrder = (usr, location) => {
    const { db, ip, port, dbuser, dbpass, url } = this.state;
    toast.configure();
    axios
      .get(url + "completeOrder", {
        params: {
          WEB_USER: usr,
          LOCATION: location,
          ip: ip,
          db: db,
          port: port,
          dbuser: dbuser,
          dbpass: dbpass,
        },
      })
      .then((response) => {
        this.setState({
          loading: false,
          cartItems: [],
        });
        toast.success("Order Completed Successfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1500,
        });
        const { cartItems } = this.state;
        {
          this.getTotalPrice(cartItems);
        }
        this.props.history.push("/orderItems");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  addCart = (product, quantity = 1) => {
    var sale_price;
    if (product.promotion_price > 0) {
      sale_price = product.promotion_price;
    } else {
      sale_price = product.itemPrice;
    }
    console.log(product);
    const { url, user, db, ip, port, dbuser, dbpass } = this.state;
    toast.configure();
    if (user.length > 0) {
      this.setState({
        loading: true,
      });
      const { user } = this.state;
      axios
        .get(url + "saveTrns", {
          params: {
            WEB_USER: user,
            NO_OF_ITEMS: "1",
            TOTAL: sale_price * quantity,
            ORDER_QUANTITY: quantity,
            ITEM_CODE: product.item_code,
            QUANTITY: quantity,
            SALE_PRICE: sale_price,
            ITEM_NAME: product.item_name,
            TRANSACTION_TOTAL: sale_price * quantity,
            SUOM: product.suom,
            ip: ip,
            db: db,
            port: port,
            dbuser: dbuser,
            dbpass: dbpass,
          },
        })
        .then((response) => {
          this.setState({
            loading: false,
            cartItems: response.data.list,
          });
          toast.success(product.item_name + " Added Successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
          });
          const { cartItems } = this.state;
          {
            this.getTotalPrice(cartItems);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      toast.info("Sign Up To Continue!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    }
  };

  removeFromCart = (orderNumber, referenceNumber, item_code) => {
    const { db, ip, port, dbuser, dbpass, url } = this.state;
    this.setState({
      loading: true,
    });
    axios
      .get(url + "removeFromCart", {
        params: {
          orderNumber: orderNumber,
          referenceNumber: referenceNumber,
          item_code: item_code,
          ip: ip,
          db: db,
          port: port,
          dbuser: dbuser,
          dbpass: dbpass,
        },
      })
      .then((response) => {
        this.setState({
          loading: false,
          cartItems: response.data.list,
        });
        toast.success("Item Removed Successfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1500,
        });
        const { cartItems } = this.state;
        {
          this.getTotalPrice(cartItems);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  changeQuantity = (
    event,
    orderNumber,
    referenceNumber,
    item_code,
    quantity,
    price
  ) => {
    const { db, ip, port, dbuser, dbpass, url } = this.state;
    this.setState({
      loading: true,
    });
    console.log(referenceNumber);
    console.log(orderNumber);
    console.log(item_code);
    console.log(price);

    axios
      .get(url + "changeQuantity", {
        params: {
          orderNumber: orderNumber,
          referenceNumber: referenceNumber,
          item_code: item_code,
          quantity: event.target.value,
          price: price,
          ip: ip,
          db: db,
          port: port,
          dbuser: dbuser,
          dbpass: dbpass,
        },
      })
      .then((response) => {
        this.setState({
          loading: false,
          cartItems: response.data.list,
        });
        const { cartItems } = this.state;
        {
          this.getTotalPrice(cartItems);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  Capitalize = (name) => {
    return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
  };
  getTotalPrice = (products) => {
    const ttamount = products?.reduce(
      (amount, product) => product.amount_value + amount,
      0
    );
    this.setState({
      cartTotal: ttamount,
    });
  };
  filterWithGroups = (itemGroup, groupName, subgroups = []) => {
    const { db, ip, port, dbuser, dbpass, url, groupsWithSubgroups } =
      this.state;
    this.setState({ itemGroupName: "All" });
    groupsWithSubgroups.forEach((each_group) => {
      if (each_group.item_group == itemGroup) {
        this.setState({
          itemGroupName: this.Capitalize(each_group.group_name),
          subgroups: each_group.subgroups,
        });
      }
    });
    window.scrollTo({ behavior: "smooth", top: "0px" });
    this.setState({
      loading: true,
      subCategoryName: "",
      itemGroupSelected: itemGroup,
      pageNumberGroup: 0,
      itemsPerPageGroup: 15,
      itemGroupCode: itemGroup,
    });
    axios
      .get(url + "getProductsGroup", {
        params: {
          group_code: itemGroup,
          ip: ip,
          db: db,
          port: port,
          dbuser: dbuser,
          dbpass: dbpass,
        },
      })
      .then((response) => {
        this.setState({
          itemGroupItems: response.data.list,
          productForGroups: response.data.list,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  filterWithSubGroups = (itemGroup, groupName, subgroup) => {
    const { db, ip, port, dbuser, dbpass, url } = this.state;
    window.scrollTo({ behavior: "smooth", top: "0px" });
    const categoryName = this.Capitalize(groupName);
    this.setState({
      loading: true,
      itemGroupName: categoryName,
      pageNumberGroup: 0,
      itemsPerPageGroup: 15,
    });
    axios
      .get(url + "filterWithSubGroups", {
        params: {
          group_code: itemGroup,
          sub_group: subgroup,
          ip: ip,
          db: db,
          port: port,
          dbuser: dbuser,
          dbpass: dbpass,
        },
      })
      .then((response) => {
        this.setState({
          itemGroupItems: response.data.list,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  sortFeatured = () => {
    const { cpSearchResults } = this.context;
    // this.setState({
    //     pageNumberSearched: 0,
    //     itemsPerSearched: 10,
    //     searchResults: cpSearchResults,
    // })
  };
  sortPriceLowToHigh = (data) => {
    this.setState({ searchResults: [] });
    data.sort((a, b) => a.itemPrice - b.itemPrice);
    this.setState({
      pageNumberSearched: 0,
      itemsPerSearched: 10,
      searchResults: data,
    });
  };
  sortPriceLowToHighItemGroups = (data) => {
    data.itemsarray.sort((a, b) => a.itemPrice - b.itemPrice);
    this.setState({
      pageNumberGroup: 0,
      itemsPerPageGroup: 15,
      itemGroupItems: data,
    });
  };
  sortPriceHighToLowItemGroups = (data) => {
    this.setState({ searchResults: [] });
    data.itemsarray.sort((a, b) => a.itemPrice - b.itemPrice).reverse();
    this.setState({
      pageNumberSearched: 0,
      itemsPerSearched: 10,
      itemGroupItems: data,
    });
  };
  sortPriceHighToLow = (data) => {
    this.setState({ searchResults: [] });
    data.sort((a, b) => a.itemPrice - b.itemPrice).reverse();
    this.setState({
      pageNumberSearched: 0,
      itemsPerSearched: 10,
      searchResults: data,
    });
  };
  SeachItems = (query) => {
    const { db, ip, port, dbuser, dbpass, url } = this.state;
    window.scrollTo({ behavior: "smooth", top: "0px" });
    this.setState({
      pageNumber: 0,
      itemsPerPage: 12,
      pageNumberSearched: 0,
      itemsPerSearched: 10,
      loading: true,
    });

    axios
      .get(url + "searchItems", {
        params: {
          query: query,
          ip: ip,
          db: db,
          port: port,
          dbuser: dbuser,
          dbpass: dbpass,
        },
      })
      .then((response) => {
        console.log(response.data.list);
        this.setState({
          searchResults: response.data.list,
          cpSearchResults: response.data.list,
          searchedWord: query,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fetchItemDetails = (query) => {
    const { db, ip, port, dbuser, dbpass, url } = this.state;
    window.scrollTo({ behavior: "smooth", top: "0px" });
    this.setState({
      loading: true,
      pageNumber: 0,
      itemsPerPage: 12,
    });

    axios
      .get(url + "fetchItemDetails", {
        params: {
          item_code: query,
          ip: ip,
          db: db,
          port: port,
          dbuser: dbuser,
          dbpass: dbpass,
        },
      })
      .then((response) => {
        this.setState({
          fullItemDetails: response.data.list,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  reduction = (id) => {
    const { cart } = this.state;
    cart.forEach((item) => {
      if (item.item_code === id) {
        item.count === 1 ? (item.count = 1) : (item.count -= 1);
      }
    });
    this.setState({ cart: cart });
    this.getTotal();
  };

  increase = (id) => {
    const { cart } = this.state;
    cart.forEach((item) => {
      if (item.item_code === id) {
        item.count += 1;
      }
    });
    this.setState({ cart: cart });
    this.getTotal();
  };

  removeProduct = (id) => {
    if (window.confirm("Do you want to delete this product?")) {
      const { cart } = this.state;
      cart.forEach((item, index) => {
        if (item._id === id) {
          cart.splice(index, 1);
        }
      });
      this.setState({ cart: cart });
      this.getTotal();
    }
  };

  getTotal = () => {
    const { cart } = this.state;
    const res = cart.reduce((prev, item) => {
      return prev + item.price * item.count;
    }, 0);
    this.setState({ total: res });
  };

  getOrderItems = (
    order_date,
    order_type,
    order_number,
    order_count,
    order_amount
  ) => {
    this.setState({
      order_number: order_number,
      order_count: order_count,
      order_amount: order_amount,
      order_type: order_type,
      order_date: order_date,
    });
  };
  setItemCode = (item_code) => {
    localStorage.setItem("item_code", JSON.stringify(item_code));
    this.setState({ itemCode: item_code });
    this.getGroupItems(item_code);
  };
  getGroupItems = (itemcode) => {
    const { db, ip, port, dbuser, dbpass, url } = this.state;

    axios
      .get(url + "getSimilarProducts", {
        params: {
          ip: ip,
          db: db,
          item_code: itemcode,
          port: port,
          dbuser: dbuser,
          dbpass: dbpass,
        },
      })
      .then((response) => {
        console.log(response.data.list);
        this.setState({
          product: response.data.list,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  changePage = ({ selected }) => {
    this.setState({ pageNumber: selected });
    window.scrollTo({ behavior: "smooth", top: "0px" });
  };
  changePageGroup = ({ selected }) => {
    this.setState({ pageNumberGroup: selected });
    window.scrollTo({ behavior: "smooth", top: "0px" });
  };
  changePageSearched = ({ selected }) => {
    this.setState({ pageNumberSearched: selected });
    window.scrollTo({ behavior: "smooth", top: "0px" });
  };
  changePageGroupd = ({ selected }) => {
    this.setState({ pageNumberGroupd: selected });
    window.scrollTo({ behavior: "smooth", top: "0px" });
  };

  componentDidUpdate() {
    localStorage.setItem("dataCart", JSON.stringify(this.state.cart));
    localStorage.setItem("dataTotal", JSON.stringify(this.state.total));
  }
  productsPage = () => {
    <Link to="/"></Link>;
  };
  componentDidMount() {
    const item_code = JSON.parse(localStorage.getItem("item_code"));
    console.log("item_code:" + item_code);
    if (item_code !== null) {
      this.setState({ itemCode: item_code });
    }
    const { db, ip, port, dbuser, dbpass, url } = this.state;
    window.scrollTo({ behavior: "smooth", top: "0px" });
    this.setState({
      loading: true,
    });
    axios
      .all([
        axios.get(url + "getCompanyName", {
          params: {
            ip: ip,
            db: db,
            port: port,
            dbuser: dbuser,
            dbpass: dbpass,
          },
        }),
        axios.get(url + "getItemGroups", {
          params: {
            ip: ip,
            db: db,
            port: port,
            dbuser: dbuser,
            dbpass: dbpass,
          },
        }),
        axios.get(url + "getProducts", {
          params: {
            ip: ip,
            db: db,
            port: port,
            dbuser: dbuser,
            dbpass: dbpass,
          },
        }),
        axios.get(url + "itemsandgroupscarousel", {
          params: {
            ip: ip,
            db: db,
            port: port,
            dbuser: dbuser,
            dbpass: dbpass,
          },
        }),
        axios.get(url + "getProductGroupsWithSubGroups", {
          params: {
            ip: ip,
            db: db,
            port: port,
            dbuser: dbuser,
            dbpass: dbpass,
          },
        }),
        axios.get(url + "locations", {
          params: {
            ip: ip,
            db: db,
            port: port,
            dbuser: dbuser,
            dbpass: dbpass,
          },
        }),
        axios.get(url + "getTopSellingProducts", {
          params: {
            ip: ip,
            db: db,
            port: port,
            dbuser: dbuser,
            dbpass: dbpass,
          },
        }),
        axios.get(url + "getWebsiteSettings", {
          params: {
            ip: ip,
            db: db,
            port: port,
            dbuser: dbuser,
            dbpass: dbpass,
          },
        }),
      ])
      .then(
        axios.spread(
          (
            response,
            response1,
            response2,
            response3,
            response4,
            response5,
            response6,
            response7
          ) => {
            // console.log(response7.data.list);
            this.setState({
              CompanyName: response.data.list,
              itemGroups: response1.data.list,
              groupscopy: response1.data.list,
              products: response2.data.list,
              homeData: response2.data.list,
              productForGroups: response2.data.list,
              itemGroupItems: response2.data.list,
              itemsWithGroups: response3.data.list,
              groupsWithSubgroups: response4.data.list,
              companyLocation: response5.data.list,
              product: response6.data.list,
              loading: false,
              settingLink: response7.data.list,
              pageNumber: 0,
              itemsPerPage: 12,
            });

            const dataCart = JSON.parse(localStorage.getItem("dataCart"));
            if (dataCart !== null) {
              this.setState({ cart: dataCart });
            }
            const dataTotal = JSON.parse(localStorage.getItem("dataTotal"));
            if (dataTotal !== null) {
              this.setState({ total: dataTotal });
            }
            const loggedInUser = JSON.parse(localStorage.getItem("user"));
            if (loggedInUser !== null) {
              {
                this.setUser(loggedInUser);
              }
            }

            {
              this.renderCompanyName();
            }
          }
        )
      )
      .catch((error) => {
        this.setState({
          loading: false,
        });
        //   console.log(error);
      });
  }
  removeGroupNameDisplay = () => {
    this.setState({
      itemGroupName: "",
    });
  };
  renderCompanyName = () => {
    const { CompanyName, cartItems } = this.state;
    if (Object.keys(CompanyName).length && CompanyName.length) {
      CompanyName.map((eacResult) => {
        const name = eacResult.FULL_NAME;
        this.setState({
          fullName: name,
        });
        {
          this.getTotalPrice(cartItems);
        }
      });
    }
  };
  getItemGroups = () => {
    const { itemsGroupsCopy } = this.state;
    this.setState({
      itemGroups: itemsGroupsCopy,
    });
  };
  adjustDisplayItems = (items) => {
    this.setState({
      displayItems: items,
    });
  };
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
      currentPage: selectedPage,
      offset: offset,
    });
  };
  render() {
    const {
      searchedWord,
      searchResults,
      settingLink,
      product,
      subCategoryName,
      itemGroupSelected,
      url,
      order_date,
      order_type,
      db,
      ip,
      port,
      dbuser,
      dbpass,
      order_count,
      order_amount,
      order_number,
      companyLocation,
      user,
      cartTotal,
      cartItems,
      itemCode,
      fullItemDetails,
      itemGroupCode,
      subgroups,
      groupsWithSubgroups,
      itemsWithGroups,
      pageNumber,
      itemsPerPage,
      pageNumberGroup,
      pageNumberSearched,
      pageNumberGroupd,
      itemsPerPageGroup,
      itemsPerSearched,
      itemsPerPageGroupd,
      displayItems,
      products,
      productForGroups,
      cart,
      total,
      loading,
      CompanyName,
      itemGroups,
      fullName,
      itemGroupName,
      itemGroupItems,
    } = this.state;
    const {
      searchWithBrandsandColors,
      sortPriceLowToHighItemGroups,
      sortPriceHighToLowItemGroups,
      sortFeatured,
      sortPriceHighToLow,
      sortPriceLowToHigh,
      changePageSearched,
      setHomeData,
      getOrderItems,
      completeOrder,
      setUser,
      saveNewCustomer,
      changeQuantity,
      removeFromCart,
      getTotalPrice,
      productsPage,
      setItemCode,
      fetchItemDetails,
      filterWithSubGroups,
      changePageGroup,
      changePage,
      addCart,
      reduction,
      increase,
      removeProduct,
      getTotal,
      filterWithGroups,
      SeachItems,
      getItemGroups,
      removeGroupNameDisplay,
      adjustDisplayItems,
    } = this;
    return (
      <DataContext.Provider
        value={{
          searchWithBrandsandColors,
          sortPriceLowToHighItemGroups,
          sortPriceHighToLowItemGroups,
          sortFeatured,
          sortPriceHighToLow,
          sortPriceLowToHigh,
          searchedWord,
          searchResults,
          settingLink,
          product,
          setHomeData,
          subCategoryName,
          itemGroupSelected,
          url,
          order_date,
          order_type,
          db,
          ip,
          port,
          dbuser,
          dbpass,
          order_count,
          order_amount,
          getOrderItems,
          order_number,
          completeOrder,
          companyLocation,
          user,
          setUser,
          saveNewCustomer,
          changeQuantity,
          removeFromCart,
          cartTotal,
          getTotalPrice,
          cartItems,
          productsPage,
          itemCode,
          setItemCode,
          fullItemDetails,
          fetchItemDetails,
          filterWithSubGroups,
          itemGroupCode,
          subgroups,
          groupsWithSubgroups,
          itemsWithGroups,
          pageNumberGroup,
          pageNumberSearched,
          itemsPerSearched,
          itemsPerPageGroup,
          pageNumberGroupd,
          itemsPerPageGroupd,
          changePageGroup,
          changePageSearched,
          changePage,
          displayItems,
          pageNumber,
          itemsPerPage,
          products,
          productForGroups,
          itemGroupItems,
          adjustDisplayItems,
          addCart,
          cart,
          reduction,
          increase,
          removeProduct,
          total,
          getTotal,
          filterWithGroups,
          SeachItems,
          loading,
          CompanyName,
          itemGroups,
          fullName,
          getItemGroups,
          itemGroupName,
          removeGroupNameDisplay,
        }}
      >
        {this.props.children}
      </DataContext.Provider>
    );
  }
}
