import { Typography, Button } from '@material-ui/core';
import React, { Component } from 'react'
import '../css/Itemgroups.css'
import { DataContext } from '../Context'

export class Itemgroups extends Component {
    static contextType = DataContext;

    renderItemGroups = () => {
        const { filterWithGroups, groupsWithSubgroups,filterWithSubGroups } = this.context;


        if (Object.keys(groupsWithSubgroups).length && groupsWithSubgroups.length) {
            return (

                <div>
                    {groupsWithSubgroups.map(eacResult => {
                        return (
                            <div key={eacResult.item_group} className="each_group">
                                {console.log(eacResult.subgroups)}
                                {eacResult.subgroups.map((eachSubgroup) => {
                                    { console.log(eachSubgroup.sub_group) }
                                   
                                })}
                                <div> <a onClick={() => filterWithGroups(eacResult.item_group, eacResult.group_name)} >{this.Capitalize(eacResult.group_name)}</a>
                                    {eacResult.subgroups.map(eachSubgroup => {
                                        <div className="each_subgroup">
                                            <a> {eachSubgroup.sub_group}</a> </div>
                                    })}
                                </div>
                                <div>{eacResult.subgroups.map(eachSubgroup => {
                                    <div className="each_subgroup">
                                        <a onClick={() => filterWithGroups(eacResult.item_group, eacResult.group_name)}> {eachSubgroup.sub_group_name}</a> </div>
                                })}</div>

                            </div>

                        )
                    })}
                </div>


            )
        }
    }
    Capitalize = (name) => {
        return name.replace(/\b(\w)/g, s => s.toUpperCase());
    }
    render() {
        const { filterWithGroups, groupsWithSubgroups ,filterWithSubGroups} = this.context;
        return (
            <div className="itemGroups">
                <div>
                    {groupsWithSubgroups.map(eacResult => {
                        return (
                            <div key={eacResult.item_group} className="each_group">
                                <div> <a onClick={() => filterWithGroups(eacResult.item_group, eacResult.group_name, eacResult.subgroups)} >{this.Capitalize(eacResult.group_name)}</a>

                                </div>
                                {eacResult.subgroups.map(eachSub => (
                                    <div className="each_sub"> ⭐<a
                                     onClick={() => filterWithSubGroups(eacResult.item_group,`${eacResult.group_name}->${this.Capitalize(eachSub.sub_group_name)}`, eachSub.sub_group)}>{this.Capitalize(eachSub.sub_group_name)}</a></div>
                                ))}

                            </div>

                        )
                    })}
                </div>

            </div>
        )
    }
}

export default Itemgroups;
