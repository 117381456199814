import React, { Component } from 'react'
import './CompletePayments.css'
import { DataContext } from './Context'
import { withRouter } from 'react-router'
import Completepaymentcomp from './Completepaymentcomp'
import CurrencyFormat from "react-currency-format";
export class CompletePayments extends Component {
    static contextType = DataContext;
    state = {
        location: ''
    }
    componentDidMount() {
        const { companyLocation } = this.context;
        if (companyLocation.length > 0) {

            this.setState({
                location: companyLocation[0].mainLocation

            })
            console.log(companyLocation[0].mainLocation);
        }
    }
    render() {
        const { cartItems, cartTotal, settingLink, user, completeOrder,companyLocation } = this.context;
        console.log(cartItems);
        return (
            <div className="CompleteMainPage">
                <div className="checkoutmain">
                    <label className="mainlvl">CHECKOUT</label>
                    <div className="addressdheader">
                        <div className="lbldvd"> <label className="AddressDetails">1. ADDRESS DETAILS</label></div>
                        <div>    <label className="pckuplbl">Pick Up Location</label>
                            <select onChange={(e) => {
                                const selectedGroup = e.target.value;
                                this.setState({ location: selectedGroup })
                                console.log("rrr");

                            }}
                                className="selectloc">
                                {companyLocation.map(eacResult => {
                                return (
                                    <option value={eacResult.mainLocation} >
                                        {eacResult.mainLocation}
                                    </option>

                                )
                            })}
                            </select></div>
                    </div>

                    <div className="addresslocation">

                        <div className="lbldvd"> <label className="AddressDetails">2. DELIVERY METHOD</label></div>
                        <div className="delivery">
                            <label className="pckuplbl">Select The Correct/Convenient Place To Pick Your Orders </label>
                            <label className="pckuplbl">Make sure you visit our Premises to Pick your orders</label>
                            <label className="pckuplbl">Payments to be made when picking Your Orders </label>
                        </div>
                        <button className="chekotbtn" style={{ color: settingLink.NAVBAR_TXT_COLOR, backgroundColor: settingLink.NAVBAR_COLOR }}
                            onClick={() =>{ completeOrder(user, this.state.location);
                            this.props.history.push('/orderItems')}}>CLICK HERE TO CHECK OUT</button>
                    </div>
                </div>
                <div className="ordersummary">
                    <label className="mainlvl">ORDER SUMMARY</label>
                    <div className="yourorderdiv">
                        <div className="yourorderdivlabe">
                            <label>YOUR ORDER({cartItems.length} items)</label>
                        </div>
                        <div className="yourorderitemsdiv">

                            {cartItems.map(item => (
                                <Completepaymentcomp product={item} />
                            ))}

                        </div>
                        <div className="yourordersub">
                            <label>Subtotal</label>
                            <CurrencyFormat

                                renderText={(value) => (
                                    <span className="">
                                        <span>{value}</span>
                                    </span>

                                )}
                                decimalScale={2}
                                value={cartTotal}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={settingLink.CURRENCY}

                            />
                        </div>
                        <div className="modifybtndiv">
                            <button onClick={() => { this.props.history.push('/shopping-cart'); }}>MODIFY CART</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(CompletePayments)
