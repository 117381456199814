import React from 'react'
import './Home.css';
import { DataContext } from './Context'

function Home() { 
   
    return (
        <div classes="Home">
            <div className="home__container">
                <img className="home__image"
                    src="http://62.12.113.90:8080/g"></img>
            </div>
        </div>
    )
}

export default Home
