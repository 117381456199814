import React from 'react'
import { DataContext } from './Context'
import axios from 'axios'
import './aboutus.css'
import Aboutuscomponent from './Aboutuscomponent'

export class AboutUs extends React.Component {
    static contextType = DataContext;
    state = {
        imgSrc: '',
        aboutUsArr: []
    }
    componentDidMount() {
        { this.getAboutUs() }
    }
    getAboutUs = () => {
        const { url, port, ip, db, dbuser, dbpass } = this.context;
        axios
            .get(url + "getWebSiteAboutUs", {

                params: {
                    ip: ip,
                    db: db,
                    port: port,
                    dbuser: dbuser,
                    dbpass: dbpass

                }
            })
            .then((response) => {
                this.setState({ aboutUsArr: response.data.list })
            })
            .catch((error) => {

                console.log(error);

            });
    }
    render() {
        const { cartItems, settingLink } = this.context;
        const { imgSrc, aboutUsArr } = this.state;

        return (
            <div className="aboutus">
                {this.state.aboutUsArr.length > 0 && this.state.aboutUsArr.map(eachAbout => (
                    < Aboutuscomponent details={eachAbout} />
                ))}



            </div>
        )
    }
}

export default AboutUs
