import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: 2,
    minHeight: 4,
    '&$expanded': {
      minHeight: 16,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}))(MuiAccordionDetails);
const useStyles = makeStyles({
  roo: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 3,
    width: 10,
    height: 10,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
});
export default function CustomizedAccordions(props) {
  const [expanded, setExpanded] = React.useState('panel1');
  const [checked, setChecked] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    const handleCheckbox = (event) => {
      setChecked(event.target.checked);
    };
  };
  const classes = useStyles();
  return (
    <div>
      {props.data.map(eacResult => {
        return (
          <div >
            <Accordion square expanded={expanded === eacResult.item_group} onChange={handleChange(eacResult.item_group)}>
              <AccordionSummary aria-controls="panel3d-content" id="panel3d-header"  expandIcon={<ExpandMoreIcon />}>
                <div> <Checkbox  className={classes.roo}
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                /> <label style={{fontSize:"15px"}} onClick={() => {
                  props.filterWithGroups(eacResult.item_group);
                }}>{props.Capitalize(eacResult.group_name)}</label></div>
                 
              </AccordionSummary>
              <AccordionDetails>
                <div className="product-category-subgroup">
                  {eacResult.subgroups.map((eachSub, index) => {
                    return (
                      <div> <Checkbox  className={classes.roo}
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />  <label className="product-category-subgrouplabel"
                        onClick={() => {
                          props.filterWithSubGroups(eacResult.item_group, `${props.Capitalize(eacResult.group_name)}->${props.Capitalize(eachSub.sub_group_name)}`, eachSub.sub_group)
                        }}>{props.Capitalize(eachSub.sub_group_name)}</label></div>
                    )

                  })}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>


        )
      })}
    </div>
  );
}
